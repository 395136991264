import React, { useContext } from "react";
import OptionField from "./component/OptionField";
import ThemeContext from "../../context/ThemeContext";
import Footer from "../../components/reuseables/Footer";
import Navbar from "../../components/reuseables/Navbar";


function Test() {
	const { themeMode } = useContext(ThemeContext);
		return (
			<section className={`App ${themeMode}`}>
				<div className="w-full">
					<Navbar id={2} />
					<div className="h-full page-bg-light  dark:page-bg-dark dark:bg-[#0c0c0cfa] bg-[#F8F8FB] bg-no-repeat w-full bg-cover ">
						<div className="w-full h-full pt-[50px]">
							<h3 className="font-ebrimabold md:text-[40px] text-[#23E42B] font-extrabold text-center">Test </h3>
							<div className="mt-[40px] w-full px-[60px] h-full ">
								<OptionField />
							</div>
						</div>
					</div>
					<Footer />
				</div>
				</section>
		)
}

export default Test