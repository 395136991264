import React from "react";
import { RiArrowRightSLine, RiArrowLeftSLine } from "react-icons/ri";
import { credit } from "../../../data/learnData";
import Slider from "react-slick";
import { RoundBtn } from "../../../components/reuseables/Buttons";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const PrevArrow = (props) => {
  const { onClick } = props;
  return (
    <div
      className="custom-prev-arrow cursor-pointer absolute z-20 top-[170px]  left-[-30px] md:left-[-40px]"
      onClick={onClick}
    >
      <RoundBtn icon={RiArrowLeftSLine} />
    </div>
  );
};

const NextArrow = (props) => {
  const { onClick } = props;
  return (
    <div
      className="custom-next-arrow cursor-pointer absolute z-20 bottom-[250px] md:bottom-[280px] right-[-30px] md:right-[-40px]"
      onClick={onClick}
    >
      <RoundBtn icon={RiArrowRightSLine} />
    </div>
  );
};

const Credit = () => {
  const settings = {
    className: "center relative",
    infinite: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    swipeToSlide: true,
    prevArrow: <PrevArrow />,
    nextArrow: <NextArrow />,
    responsive: [
      {
        // Breakpoint for mobile devices
        breakpoint: 976,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <div>
      <div className="mx-auto px-2 md:px-20">
        <div className="my-[60px]">
          <h1 className="text-[24px] md:text-[48px] font-ebrimabold dark:text-white text-black">
            Credit
          </h1>
          <p className="text-[18px] md:text-[24px] dark:text-white text-black">
            Get comfortable with the basics.
          </p>
          <div className="mt-[20px] md:mt-[40px] mx-[5px] md:mx-0">
            <Slider {...settings}>
              {credit.map((item) => (
                <div key={item.id} className="w-[450px] h-[450px]">
                  <img
                    src={item.image}
                    alt="Real Estate"
                    className="h-[380px] md:h-full w-full"
                  />
                  <p className="px-[20px] font-ebrimabold text-[18px] md:text-[24px] dark:text-white text-black text-center">
                    {item.title}
                  </p>
                </div>
              ))}
            </Slider>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Credit;
