import React from "react";
import { Link } from "react-router-dom";


import { FaFacebook, FaDiscord } from "react-icons/fa";
import { AiFillTwitterCircle, AiOutlineInstagram, AiFillLinkedin, AiFillYoutube, AiFillRedditCircle } from "react-icons/ai";

const Footer = () => {



  return (
    <>
      <section className="bg-white dark:bg-black pt-[59px] w-full">
        <div className="md:px-12 px-0 w-full border-b border-[#42464E]">
          <div className=" mx-auto md:w-full w-11/12 md:py-8 py-6">
            <div className="md:grid md:grid-cols-3 flex flex-col md:mx-10 justify-between gap-4 w-full mx-auto px-3">
              <div className="flex flex-col md:flex-grow md:items-start items-center md:justify-start w-full grid-cols-none">
                <img src="https://user-images.githubusercontent.com/43154722/230801877-ba49eaeb-1e14-4df7-832f-52c44b80687f.svg" alt="Logo" className="md:w-[380px] w-[300px]" />
                <p className="font-ebrima font-normal text-black dark:text-white leading-[32px] pb-[50px]  text-base md:text-left text-center md:w-[400px] w-[349px]">
                  "Our vision is to facilitate a global connection of
                  individuals through a Metaverse environment, providing an
                  opportunity for learning and socialization that enhances their
                  overall experience."
                </p>
              </div>
              <div className="grid grid-cols-2 md:mt-0 mt-4 w-full">
                <div className="flex flex-col items-start justify-start w-full">
                  <h3 className="font-ebrimabold text-black dark:text-white font-extrabold text-xl pb-5">
                    Collections
                  </h3>

                <ul className="w-full">
                    <li className={`cursor-pointer font-normal my-2`}>
                      <Link
                        to="/"
                        className="text-black dark:text-white font-normal leading-[28px] font-ebrima text-base"
                      >
                        All NFTs
                      </Link>
                    </li>
                    <li className={`cursor-pointer font-normal my-2`}>
                      <Link
                        to="/"
                        className="text-black dark:text-white font-normal leading-[28px] font-ebrima text-base"
                      >
                        Hands of Wealth
                      </Link>
                    </li>
                    <li className={`cursor-pointer font-normal my-2`}>
                      <Link
                        to="/"
                        className="text-black dark:text-white font-normal leading-[28px] font-ebrima text-base"
                      >
                        Student ID
                      </Link>
                    </li>
                    <li className={`cursor-pointer font-normal my-2`}>
                      <Link
                        to="/"
                        className="text-black dark:text-white font-normal leading-[28px] font-ebrima text-base"
                      >
                        Token
                      </Link>
                    </li>
                    <li className={`cursor-pointer font-normal my-2`}>
                      <Link
                        to="/"
                        className="text-black dark:text-white font-normal leading-[28px] font-ebrima text-base"
                      >
                        Male
                      </Link>
                    </li>
                    <li className={`cursor-pointer font-normal my-2`}>
                      <Link
                        to="/"
                        className="text-black dark:text-white font-normal leading-[28px] font-ebrima text-base"
                      >
                        Female
                      </Link>
                    </li>
                  </ul>
                </div>
                <div className="flex flex-col items-start justify-start w-full">
                  <h3 className="font-ebrimabold text-black dark:text-white font-extrabold text-xl pb-5">
                    My Account
                  </h3>

                   <ul className="w-full">
                    <li className={`cursor-pointer font-normal my-2`}>
                      <Link
                        to="/"
                        className="text-black dark:text-white font-normal leading-[28px] font-ebrima text-base "
                      >
                        Profile
                      </Link>
                    </li>
                    <li className={`cursor-pointer font-normal my-2`}>
                      <Link
                        to="/"
                        className="text-black dark:text-white font-normal leading-[28px] font-ebrima text-base "
                      >
                        Favorite
                      </Link>
                    </li>
                    <li className={`cursor-pointer font-normal my-2`}>
                      <Link
                        to="/"
                        className="text-black dark:text-white font-normal leading-[28px] font-ebrima text-base "
                      >
                        My Collections
                      </Link>
                    </li>
                    <li className={`cursor-pointer font-normal my-2`}>
                      <Link
                        to="/"
                        className="text-black dark:text-white font-normal leading-[28px] font-ebrima text-base"
                      >
                        Settings
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="grid grid-cols-2 md:mt-0 mt-10 w-full">
                <div className="flex flex-col items-start justify-start w-full">
                  <h3 className="font-ebrimabold text-black dark:text-white font-extrabold  text-xl pb-5">
                    Company
                  </h3>

                  <ul className="w-full">
                    <li className={`cursor-pointer font-normal my-2`}>
                      <Link
                        to="/blog"
                        className="text-black dark:text-white font-normal leading-[28px] font-ebrima text-base"
                      >
                        Blog
                      </Link>
                    </li>
                    <li className={`cursor-pointer font-normal my-2`}>
                      <Link
                        to="/"
                        className="text-black dark:text-white font-normal leading-[28px] font-ebrima text-base"
                      >
                        About
                      </Link>
                    </li>
                    <li className={`cursor-pointer font-normal my-2`}>
                      <Link
                        to="/"
                        className="text-black dark:text-white font-normal leading-[28px] font-ebrima text-base"
                      >
                        Partners
                      </Link>
                    </li>
                    <li className={`cursor-pointer font-normal my-2`}>
                      <Link
                        to="/"
                        className="text-black dark:text-white font-normal leading-[28px] font-ebrima text-base"
                      >
                        Help Center
                      </Link>
                    </li>
                    <li className={`cursor-pointer font-normal my-2`}>
                      <Link
                        to="/"
                        className="text-black dark:text-white font-normal leading-[28px] font-ebrima text-base"
                      >
                        Careers
                      </Link>
                    </li>
                    <li className={`cursor-pointer font-normal my-2`}>
                      <Link
                        to="/"
                        className="text-black dark:text-white font-normal leading-[28px] font-ebrima text-base"
                      >
                        Event
                      </Link>
                    </li>
                  </ul>
                </div>
                <div className="flex flex-col items-start justify-start w-full">
                  <h3 className="font-ebrimabold text-black dark:text-white font-extrabold md:text-xl text-xl pb-5">
                    Learn
                  </h3>

           <ul className="w-full">
                    <li className={`cursor-pointer font-normal my-2`}>
                      <Link
                        to="/"
                        className="text-black dark:text-white font-normal leading-[28px] font-ebrima text-base"
                      >
                        Real Estate
                      </Link>
                    </li>
                    <li className={`cursor-pointer font-normal my-2`}>
                      <Link
                        to="/"
                        className="text-black dark:text-white font-normal leading-[28px] font-ebrima text-base"
                      >
                        Credit
                      </Link>
                    </li>
                    <li className={`cursor-pointer font-normal my-2`}>
                      <Link
                        to="/"
                        className="text-black dark:text-white font-normal leading-[28px] font-ebrima text-base"
                      >
                        Crypto
                      </Link>
                    </li>
                    <li className={`cursor-pointer font-normal my-2`}>
                      <Link
                        to="/"
                        className="text-black dark:text-white font-normal leading-[28px] font-ebrima text-base"
                      >
                        Blockchain Technology
                      </Link>
                    </li>
                    <li className={`cursor-pointer font-normal my-2`}>
                      <Link
                        to="/"
                        className="text-black dark:text-white font-normal leading-[28px] font-ebrima text-base"
                      >
                        Metaverse
                      </Link>
                    </li>
                    <li className={`cursor-pointer font-normal my-2`}>
                      <Link
                        to="/"
                        className="text-black dark:text-white font-normal leading-[28px] font-ebrima text-base"
                      >
                        NFTs
                      </Link>
                    </li>
                    <li className={`cursor-pointer font-normal my-2`}>
                      <Link
                        to="/"
                        className="text-black dark:text-white font-normal leading-[28px] font-ebrima text-base"
                      >
                        Web3
                      </Link>
                    </li>
                    <li className={`cursor-pointer font-normal my-2`}>
                      <Link
                        to="/"
                        className="text-black dark:text-white font-normal leading-[28px] font-ebrima text-base"
                      >
                        Digital Asset
                      </Link>
                    </li>
                    <li className={`cursor-pointer font-normal my-2`}>
                      <Link
                        to="/"
                        className="text-black dark:text-white font-normal leading-[28px] font-ebrima text-base"
                      >
                        A.I
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="md:py-14 py-2">
          <div className="mx-auto w-full">
            <div className="md:px-20 px-10 flex flex-col md:flex-row items-center justify-between">
              <div className="md:mt-0 mt-8">
                <ul className="flex items-center justify-between">
                  <li className="mr-6">
                    <Link to="https://www.youtube.com/channel/UC3ULOmjYOjBvwnQGsg8sagQ" target="_blank">
                      <AiFillYoutube
                        className="text-black dark:text-white md:text-5xl text-4xl"
                      />
                    </Link>
                  </li>
                  <li className="mr-6">
                    <Link
                      to="https://www.linkedin.com/in/will-stanley-104202243/" target="_blank"
                      className="md:h-[55px] md:w-[55px] h-[40px] w-[40px] flex items-center justify-center rounded-[50%] bg-black dark:bg-white"
                    >
                      <AiFillLinkedin
                        className="text-white dark:text-black md:text-4xl text-3xl"
                      />
                    </Link>
                  </li>
                  <li className="mr-6">
                    <Link to="https://twitter.com/globalassetu" target="_blank">
                      <AiFillTwitterCircle
                        className="text-black dark:text-white md:text-5xl text-4xl"
                      />
                    </Link>
                  </li>
                  <li className="mr-6">
                    <Link to="https://discord.gg/zdRZpHwa" target="_blank">
                      <FaDiscord
                        className="text-black dark:text-white md:text-5xl text-4xl"
                      />
                    </Link>
                  </li>
                  <li>
                    <Link to="https://www.reddit.com/user/Globalassetuniverse" target="_blank">
                      <AiFillRedditCircle
                        className="text-black dark:text-white md:text-5xl text-4xl"
                      />
                    </Link>
                  </li>
                </ul>
              </div>
              <div className="capitalize text-sm md:mt-0 mt-5 px-[16px] md:px-0 w-full md:text-end text-center">
                <span className="font-normal font-ebrima text-[#A3A3AB]">
                  Copyright © 2022.Created by
                </span>{" "}
                <span className="font-extrabold font-ebrimabold text-black dark:text-white ">
                  Global Asset University .LLC
                </span>
              </div>
             
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Footer;
