import React, { useContext, useState, useEffect } from "react";
import Navbar from "../components/reuseables/Navbar";
import ThemeContext from "../context/ThemeContext";
import { Link } from "react-router-dom";
import { FaTwitter, FaDiscord } from "react-icons/fa";
import { FiInstagram } from "react-icons/fi";
import { alertActions } from "../actions/AlertActions";
import { InputField, } from "../components/reuseables/Input";

const ComingSoon = () => {
  const { themeMode } = useContext(ThemeContext);
  const [countdown, setCountdown] = useState(new Date("2023-04-28T00:00:00"));

  const getTimeRemaining = () => {
    const time = Date.parse(countdown) - Date.parse(new Date());
    const days = Math.floor(time / (1000 * 60 * 60 * 24));
    const hours = Math.floor((time / (1000 * 60 * 60)) % 24);
    const minutes = Math.floor((time / 1000 / 60) % 60);
    return { days, hours, minutes };
  };

  useEffect(() => {
    const interval = setInterval(() => {
      setCountdown(new Date("2023-04-28T00:00:00"));
    }, 1000);
    return () => clearInterval(interval);
  }, []);


  const [formData, setFormData] = useState({
    email: '',

  });

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    let isValid = true;
    if (!formData.email) {
      alertActions.error("Please enter your Email Address");
      isValid = false;
    }
     if (isValid) {
      // loginNewUser();
    }
  };

  const TimeItem = ({ time, title }) => {
    return (
      <div className="md:p-[2px] p-[1.2px] md:rounded-[30px] rounded-[15px] overflow-hidden bg-gradient-to-r from-[#CD23E4] via-[#808080] to-[#23E42B] md:mx-7 mx-3">
        <div className="md:px-6 px-5 md:py-4 py-3 w-full md:rounded-[30px] rounded-[15px] dark:bg-[#0C0C0C] bg-[#F8F8FB] flex items-center justify-center">
          <div className="flex flex-col md:flex-row items-center justify-center w-full">
            <h3 className="text-black dark:text-white md:text-[34px] text-[24px] font-extrabold font-ebrimabold">
              {time}
            </h3>
            <h3 className="text-[#808080] md:text-[22px] text-[16px] font-extrabold font-ebrimabold md:pl-4 pl-0">
              {title}
            </h3>
          </div>
        </div>
      </div>
    );
  };

  return (
    <section className={`App ${themeMode}`}>
        <div className="w-full">
          <Navbar id={2} />
        <div className="h-screen pt-[70px] page-bg-light  dark:page-bg-dark dark:bg-[#0c0c0cfa] bg-[#F8F8FB] bg-no-repeat w-full bg-cover">
            <div className="container mx-auto md:w-full w-11/12 flex flex-col items-center justify-center font-extrabold font-ebrimabold text-[102px]">
              <h3 className="text-black dark:text-white md:text-[75px] text-center text-[30px]">Coming Soon</h3>
              <div className="my-7 flex items-center w-full justify-center">
                <TimeItem time={getTimeRemaining().days} title="DAYS" />{" "}
                <span className="md:text-[34px] text-[20px] text-[#808080]">:</span>
                <TimeItem time={getTimeRemaining().hours} title="HOURS" />{" "}
              <span className="md:text-[34px] text-[20px] text-[#808080]">:</span>
                <TimeItem time={getTimeRemaining().minutes} title="MINUTES" />
              </div>
              <h3 className="font-medium md:text-[22px] text-base leading-[22px] text-[#808080] font-ebrima text-center pt-2 pb-4">
                Get notified when we are close to blast off:
            </h3>
            <form onSubmit={handleSubmit}>
              <div className="flex flex-col md:flex-row items-center py-10 w-full justify-center mx-20">
                <div className="p-[2px] rounded-[20px] overflow-hidden bg-gradient-to-r from-[#CD23E4] via-[#808080] to-[#23E42B] mx-7 h-[71px] flex items-center md:w-[391px] w-10/12">
                  <div className="dark:bg-[#0C0C0C] bg-[#F8F8FB] flex items-center h-full w-full rounded-[20px]">
                    {/* <input
                      type="email"
                      className="text-black opacity-40 dark:text-white dark:opacity-60 text-base w-full h-full md:px-6 px-4 md:py-4 py-2 dark:bg-[#0C0C0C] bg-[#F8F8FB] focus:outline-none rounded-[20px] font-medium font-ebrima"
                      placeholder="Enter your email..."
                  /> */}
                  <InputField
                    type="email"
                    placeholder="Enter your email..."
                    name="email"
                    value={formData.email}
                    onChange={handleInputChange}
                  />
                  </div>
                </div>

              <button type="submit" className="px-2 md:py-5 py-4 md:mt-0 mt-10 focus:outline-none bg-gradient-to-r from-[#CD23E4] via-[#808080] to-[#23E42B] rounded-[50px] w-[190px]">
                  Subscribe
                </button>
              </div>
          </form>
              <h3 className="font-medium md:text-[20px] text-base leading-[20px] text-[#808080] font-ebrima text-center py-4">
                If you have any questions, please contact us at:
              </h3>
              <h3 className="font-medium md:text-[22px] text-base leading-[22px] font-ebrima text-center bg-clip-text bg-gradient-to-r from-[#CD23E4] via-[#808080] to-[#23E42B] text-transparent py-6">
                info@globalassetuniversity.eth
              </h3>
              <div className="mt-8">
                <ul className="flex items-center">
                  <li className="mx-5">
                    <Link to="">
                      <FaTwitter className="text-black opacity-40 dark:text-white dark:opacity-60 text-[28px]" />
                    </Link>
                  </li>
                  <li className="mx-5">
                    <Link to="">
                      <FiInstagram className="text-black opacity-40 dark:text-white dark:opacity-60 text-[28px]" />
                    </Link>
                  </li>
                  <li className="mx-5">
                    <Link to="">
                      <FaDiscord className="text-black opacity-40 dark:text-white dark:opacity-60 text-[28px]" />
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
    </section>
  );
};

export default ComingSoon;
