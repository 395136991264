import React from "react";
import { CircleBtn } from "../../../components/reuseables/Buttons";
import TrendingCard from "../../home/components/TrendingCards";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const PrevArrow = (props) => {
  const { onClick } = props;
  return (
    <div
      className="custom-prev-arrow cursor-pointer absolute z-20 md:top-[-135px] top-[440px] left-[30%] md:left-[85%]"
      onClick={onClick}
    >
      <CircleBtn type="prev" fade={true} />
    </div>
  );
};

const NextArrow = (props) => {
  const { onClick } = props;
  return (
    <div
      className="custom-next-arrow cursor-pointer absolute z-20 md:top-[-135px] top-[440px] right-[30%] md:right-[15px]"
      onClick={onClick}
    >
      <CircleBtn type="next" />
    </div>
  );
};

const CardSlider = ({ title, datas, id }) => {
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    swipeToSlide: true,
    prevArrow: <PrevArrow />,
    nextArrow: <NextArrow />,
    responsive: [
      {
        // Breakpoint for mobile devices
        breakpoint: 676,
        settings: {
          slidesToShow: 1,
          // slidesToScroll: 1,
          centerMode: true,
          centerPadding: "60px",
        },
      },
    ],
  };

  return (
    <section className={`w-full bg-center first:pt-0 ${id === 1? `` : `pt-[150px]`}`}>
      <div className="md:px-10 px-0 mx-auto md:w-full w-11/12">
        <div className="">
          <div className="flex items-center justify-between md:pt-[124px] pt-[70px] pb-[30px]  w-full">
            <div className="flex items-start">
              <div className="flex items-start">
                <h3 className="text-black dark:text-white md:text-[35px] text-base font-extrabold font-ebrimabold md:pb-[70px] md:pt-[40px]">
                  {title}
                </h3>
              </div>
            </div>
          </div>

          <div className="w-full">
            <Slider {...settings}>
              {datas.map((data) => (
                <div className="first:ml-[55px] md:first:ml-[170px]">
                  <TrendingCard
                    img={data.image}
                    type={2}
                    title={data.title}
                  />
                </div>
              ))}
            </Slider>
          </div>
        </div>
      </div>
    </section>
  );
};

export default CardSlider;
