import React, { useContext, useState } from "react";
import { Link } from "react-router-dom";
import { FcGoogle } from "react-icons/fc";
import { FaFacebookSquare } from "react-icons/fa";
import {
  InputField
} from "../../components/reuseables/Input";
import * as Icon from "react-icons/fi";
import Checkbox from "react-custom-checkbox";
import ThemeContext from "../../context/ThemeContext";
import sideBg from "../../img/sideBg.svg";
import { alertActions } from "../../actions/AlertActions";
import { createUser } from "../api";

function SignUp() {
  const { themeMode } = useContext(ThemeContext);

  const [formData, setFormData] = useState({
    fullname: '',
    email: '',
    phone: '',
    password: '',
    cpassword: '',

  });

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    let isValid = true;
    if (!formData.fullname) {
      alertActions.error("Please enter your Fullname");
      isValid = false;
    }
    if (!formData.email) {
      alertActions.error("Please enter your Email Address");
      isValid = false;
    }
    if (!formData.phone) {
      alertActions.error("Please enter your Phone Number");
      isValid = false;
    }
    if (!formData.password) {
      alertActions.error("Please enter a password ");
      isValid = false;
    }

    if (formData.password !== formData.cpassword) {

      alertActions.error("Both Password do not match");
      // Perform form submission or dispatch action
    } if (isValid) {
      createNewUser();
    }
  };

  const createNewUser = async () => {
    const data = { fullname: formData.fullname, email: formData.email, phone: formData.phone, password: formData.password };
    createUser(data)
      .then((res) => {
        // (res?.data?.data?.message);
        console.log(res?.data?.message)
        alertActions.success(res?.data?.message);
      })
      .catch((error) => {
       
       alertActions.error(error?.response?.data?.message);
      });
  };

  return (
    <section className={`App ${themeMode}`}>
      <div className="dark:bg-[#0C0C0C] bg-[#f8f8fb">
        <div className="container mx-auto py-[55px] md:py-[100px]">
          <div className="grid md:grid-cols-1 lg:grid-cols-2 items-start gap-x-[100px] px-[16px]">
            <div className="md:w-full h-[138px] md:h-[80%] bg-gradient-to-r from-[#CD23E4] via-[#808080] to-[#23E42B] rounded-[30px] p-[3px]">
              <div className="h-full w-full flex flex-col justify-center items-center rounded-[30px] dark:bg-[#0C0C0C] bg-[#F8F8FB] p-[5px]">
                <img
                  src={sideBg}
                  alt="Login"
                  className="h-full w-full object-cover rounded-[30px]"
                />
              </div>
            </div>
            <div className="md:w-full">
              <h1 className="text-[#23E42B] mt-[35px] md:mt-0 text-[18px] md:text-[35px] text-center font-ebrimabold ebrima">
                Get Started
              </h1>
              <p className="dark:text-white text-black text-base md:text-[22px] pt-[10px] text-center">
                Already have an account?{" "}
                <Link to="/login" className="text-[#23E42B]">
                  Log in
                </Link>
              </p>
              {/* <div className="flex justify-between gap-x-[30px] mt-[50px]">
                <div className="w-full md:max-w-[317px] h-[50px] md:h-[70px] bg-gradient-to-r from-[#CD23E4] via-[#808080] to-[#23E42B] rounded-[30px] p-[2px]">
                  <div className="h-full w-full rounded-[30px] dark:bg-[#0C0C0C] bg-[#F8F8FB] p-2 flex justify-center items-center">
                    <div className="dark:text-white text-black flex items-center justify-center text-[11px] md:text-[22px] p-[6px] md:py-[14px] md:px-[18px]">
                      Sign up with Google{" "}
                      <FcGoogle size={30} className="pl-2" />
                    </div>
                  </div>
                </div>
                <div className="w-full md:max-w-[317px] h-[50px] md:h-[70px] bg-gradient-to-r from-[#CD23E4] via-[#808080] to-[#23E42B] rounded-[30px] p-[2px]">
                  <div className="h-full w-full rounded-[30px] dark:bg-[#0C0C0C] bg-[#F8F8FB] p-2 flex justify-center items-center overflow-hidden">
                    <div className="dark:text-white text-black flex items-center justify-center text-[11px] md:text-[22px] p-[6px] md:py-[14px] md:px-[18px]">
                      Sign up with facebook
                      <FaFacebookSquare
                        size={30}
                        color={"#2F7EE0"}
                        className="pl-2"
                      />
                    </div>
                  </div>
                </div>
              </div> */}

              <form onSubmit={handleSubmit}>

                <div className="mt-[30px] md:mt-[44px]">
                  <label
                    htmlFor="email"
                    className="dark:text-white text-black text-base md:text-[20px]"
                  >
                   Full Name
                  </label>
                  <div className="w-full bg-gradient-to-r from-[#CD23E4] via-[#808080] to-[#23E42B] rounded-[30px] p-[2px] mt-[10px] md:mt-[18px]">
                    <InputField
                      type="text"
                      placeholder="Enter Fullname"
                      name="fullname"
                      value={formData.fullname}
                      onChange={handleInputChange}
                    />
                  </div>
                </div>
                <div className="mt-[20px] md:mt-[30px]">
                  <label
                    htmlFor="email"
                    className="dark:text-white text-black text-base md:text-[20px]"
                  >
                    Email Address
                  </label>
                  <div className="w-full bg-gradient-to-r from-[#CD23E4] via-[#808080] to-[#23E42B] rounded-[30px] p-[2px] mt-[10px] md:mt-[18px]">
                   
                    <InputField
                      type="email"
                      placeholder="Enter a valid email address"
                      name="email"
                      value={formData.email}
                      onChange={handleInputChange}
                    />
                  </div>
                </div>
                <div className="mt-[20px] md:mt-[30px]">
                  <label
                    htmlFor="text"
                    className="dark:text-white text-black text-base md:text-[20px] "
                  >
                    Phone Number
                  </label>
                  <div className="w-full bg-gradient-to-r from-[#CD23E4] via-[#808080] to-[#23E42B] rounded-[30px] p-[2px] mt-[10px] md:mt-[18px]">
                    <InputField
                      type="text"
                      placeholder="Enter Phone Number"
                      name="phone"
                      value={formData.phone}
                      onChange={handleInputChange}
                    />
                  </div>
                </div>
                <div className="mt-[20px] md:mt-[30px]">
                  <label
                    htmlFor="password"
                    className="dark:text-white text-black text-base md:text-[20px] "
                  >
                    Password
                  </label>
                  <div className="w-full bg-gradient-to-r from-[#CD23E4] via-[#808080] to-[#23E42B] rounded-[30px] p-[2px] mt-[10px] md:mt-[18px]">
                    <InputField
                      type="password"
                      placeholder="Enter a password you can remember"
                      name="password"
                      value={formData.password}
                      onChange={handleInputChange}
                    />
                  </div>
                </div>
                <div className="mt-[20px] md:mt-[30px]">
                  <label
                    htmlFor="password"
                    className="dark:text-white text-black text-base md:text-[20px] "
                  >
                    Confirm Password
                  </label>
                  <div className="w-full bg-gradient-to-r from-[#CD23E4] via-[#808080] to-[#23E42B] rounded-[30px] p-[2px] mt-[10px] md:mt-[18px]">
                    <InputField
                      type="password"
                      placeholder="Confirm password"
                      name="cpassword"
                      value={formData.cpassword}
                      onChange={handleInputChange}
                    />
                  </div>
                </div>
                <div className="flex gap-x-2 text-sm md:text-[18px] dark:text-white text-black mt-[23px] md:mt-[40px]">
                  <Checkbox
                    icon={<Icon.FiCheck size={14} className="dark:text-white text-[#174A41]"/>}
                    name="my-input"
                    checked={true}
                    onChange={(value, event) => {
                      let p = {
                        isTrue: value,
                      };
                      console.log(p);
                    }}
                    borderColor="#D9D9D9"
                    style={{ cursor: "pointer" }}
                    labelStyle={{ marginLeft: 5, userSelect: "none" }}
                  />
                  <div className="dark:text-white text-black"> I agree to the 
                  <Link to="/tac" className="text-[#23E42B] ml-1">Terms and Conditions.</Link>
                  </div>
                </div>
                <div className="flex justify-center mt-[50px] md:mt-[58px]">
                  <button className="button py-[8px] md:py-[16px] focus:outline-none bg-gradient-to-r from-[#CD23E4] via-[#808080] to-[#23E42B]" type="submit">Sign up</button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default SignUp;
