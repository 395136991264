import React, { useContext } from "react";
import ThemeContext from "../../context/ThemeContext";
import Category from "./components/Category";
import Hero from "./components/Hero";
import Navbar from "./components/Navbar";
import Footer from "../../components/reuseables/Footer";

function BlogPage() {
  const { themeMode } = useContext(ThemeContext);
  return (
    <div className={`${themeMode}`}>
      <Navbar />
      <div className="page-bg-light  dark:page-bg-dark dark:bg-[#0c0c0cfa] bg-[#F8F8FB] bg-contain bg-no-repeat">
        <Hero />
        <Category />
      </div>
      <Footer />
    </div>
  );
}

export default BlogPage;
