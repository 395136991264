import React from 'react'
import logo from "../../../img/logo.png";
import { Link } from "react-router-dom";
import { BiUserCircle } from "react-icons/bi";

const Nav = () => {
		return (
			<section className='bg-black w-full'>
				<div className='md:px-20 px-0 mx-auto md:w-full w-11/12'>
					<div className='flex items-center justify-between py-6'>
					<div className="logo">
						<Link to="/" className="flex items-center">
							<img
								src={logo}
								alt="Logo"
									className="md:h-[80px] md:w-[90px] h-[50px] w-[50px] pointer-events-none"
								/>
								<h3 className="font-extrabold font-ebrimabold text-white md:text-3xl text-[18px] md:pl-4 pl-2">GlobalAsset <span className='text-[#23E42B]'>Learn</span></h3>
						</Link>
					</div>
					<div>
						<BiUserCircle className="text-white md:text-[55px] text-[38px]" />
						</div>
					</div>
				</div>
						
				</section>
		)
}

export default Nav
