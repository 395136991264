import React from "react";
import { Carousel } from "react-responsive-carousel";
import { Link } from "react-router-dom";
import { RiArrowLeftSLine, RiArrowRightSLine } from "react-icons/ri";
import sideBg from "../../../img/sideBg.svg";
import "react-responsive-carousel/lib/styles/carousel.min.css";

const blogData = [
  {
    id: 1,
    image: sideBg,
    lists: ["Product", "Developer"],
    title: "January 2023 Product Update",
    date: "February 14, 2023",
    description:
      "In January, we took in our community’s feedback and built the tools and features that our users have been requesting the most. For the collectors out there, we’ve made huge speed improvements to buying and using OpenSea, including.",
  },
  {
    id: 2,
    image: sideBg,
    lists: ["Product", "Services"],
    title: "May 2023 Product Update",
    date: "May 16, 2023",
    description:
      "In January, we took in our community’s feedback and built the tools and features that our users have been requesting the most. For the collectors out there, we’ve made huge speed improvements to buying and using OpenSea, including.",
  },
  {
    id: 3,
    image: sideBg,
    lists: ["Product", "Stack"],
    title: "2023 Product Update",
    date: "July 14, 2022",
    description:
      "In January, we took in our community’s feedback and built the tools and features that our users have been requesting the most. For the collectors out there, we’ve made huge speed improvements to buying and using OpenSea, including.",
  },
];

function Hero() {
  return (
    <section className=" py-[40px]">
      <Carousel
        autoPlay
        infiniteLoop8
        interval="2000"
        showThumbs={false}
        className="relative md:py-[50px] px-[16px] md:px-0"
        renderArrowPrev={(clickHandler, hasPrev) => {
          return (
            <div
              className={`${
                hasPrev ? "absolute" : "hidden"
              } w-[62px] h-[62px] bg-white bottom-[50%] left-[0] md:left-[10%] flex justify-center items-center px-3 cursor-pointer z-20 border border-black rounded-full`}
              onClick={clickHandler}
            >
              <RiArrowLeftSLine className="w-9 h-9 text-black " />
            </div>
          );
        }}
        renderArrowNext={(clickHandler, hasNext) => {
          return (
            <div
              className={`${
                hasNext ? "absolute" : "hidden"
              } w-[62px] h-[62px] bg-white bottom-[50%] right-[0] md:right-[8.5%] flex justify-center items-center px-3 cursor-pointer z-20 border border-black rounded-full`}
              onClick={clickHandler}
            >
              <RiArrowRightSLine className="w-9 h-9 dark:text-black " />
            </div>
          );
        }}
      >
        {blogData.map((item) => (
          <div
            key={item.id}
            className="md:max-w-[80%] p-[10px] md:p-[30px] md:rounded-[30px] rounded-[15px] dark:bg-[#3E3E3E] bg-white blog-card text-start mx-[20px] md:mx-auto"
          >
            <div className="flex flex-col items-center md:flex-row md:gap-x-[30px]">
              <div className="w-full max-w-[850px] h-[130px] lg:h-[500px] md:h-[440px] mx-auto">
                <img
                  src={item.image}
                  alt="Blog"
                  className="w-full h-full object-cover md:rounded-[10px] rounded-[15px]"
                />
              </div>
              <div className="max-w-[724px] w-full">
                <ul className="flex gap-x-[20px] mt-[10px] md:mt-0">
                  {item.lists.map((list) => (
                    <li
                      key={list}
                      className="blog-list px-[15px] py-[6px] md:py-[8px] md:px-[20px]"
                    >
                      {list}
                    </li>
                  ))}
                </ul>
                <h2 className="mt-[20px] font-ebrimabold text-[24px] md:text-[36px] dark:text-white text-black">
                  {item.title}
                </h2>
                <strong className="mt-[11px] text-base md:text-[18px] dark:text-white text-black">
                  {item.date}
                </strong>
                <p className="mt-[30px] text-[16px] md:text-[18px] dark:text-white text-black leading-[40px]">
                  {item.description}
                </p>

                <div className="my-[20px] md:mt-[60px]">
                  <Link className="view-post py-[8px] px-[16px] md:px-[40px] md:py-[20px]">
                    View Post
                  </Link>
                </div>
              </div>
            </div>
          </div>
        ))}
      </Carousel>
    </section>
  );
}

export default Hero;
