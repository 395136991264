import React, { useState } from "react";
import CollectionCard from "./CollectionCard";

function RowCardNew({ data }) {

	const [expanded, setExpanded] = useState(false);

	const toggleText = () => {
		setExpanded(!expanded);
	};

	const maxSentencesToShow = 2;
	const sentences = data.description.split('. ');
	const displaySentences = expanded ? sentences : sentences.slice(0, maxSentencesToShow);

		return (
			<section className="py-[100px] w-full">
				<div className="md:mx-[95px] mx-0 px-0 w-full ">
					<div className="mx-auto md:w-full w-11/12 md:py-8 py-6">
						<div className={`flex items-center md:w-[91%] w-full gap-8 ${0 % 2 === 0 && data.id % 2 !== 0
								? "md:flex-row-reverse flex-col"
								: "flex-col md:flex-row"
        }`}>
						<div className='md:h-[594px] h-[365px] md:w-[40%] w-full'>
								<CollectionCard data={data} />
						</div>
							<div className={`md:h-[594px] h-[365px] md:w-[60%] w-full ${expanded ? `overflow-y-auto multi-scrol` : ``}`}>
								<div className="flex flex-col md:items-start md:justify-start items-center justify-center my-[30px] mx-0">
								<h3 className="font-ebrimabold text-black dark:text-white md:text-[35px] text-lg ">
									{data.title}
									</h3>
								
										<p className="font-ebrima text-black dark:text-white md:text-xl text-sm md:leading-[48px] leading-[28px] md:my-6 my-3 md:text-left text-center z-30">
										{displaySentences.join('. ')}
										</p>
									{sentences.length > maxSentencesToShow && (
										<div className="cursor-pointer font-ebrima text-black dark:text-white md:text-xl text-sm z-50" onClick={toggleText}>{expanded ? 'See less' : 'See more'}</div> 
									)}
									
								</div>
							</div>
						</div>
					</div>

				</div>
			</section>
		)
}

export default RowCardNew