import React, {useState} from "react";
import { Link } from "react-router-dom";
import Navbar from "../../../components/reuseables/Navbar";

const Hero = () => {
  const [showFullContent, setShowFullContent] = useState(false);
  const content = "Our vision is rooted in the idea that technology can be used to bring people closer together, and we believe that the Metaverse represents the next frontier in creating a truly global community. By providing a digital environment where people can connect, socialize, and learn together, we hope o foster a sense of unity and shared purpose that transcends geographic and cultural barriers.Through our Metaverse environment, individuals from all over the world will be able to interact and engage with one another, learning from each others experiences an perspectives.Weather they are attending virtual classes or workshops, participating in online meet - ups and events, or simply exploring the digital landscape, users will have the opportunity to connect with others who share their interests and passions.Our vision is not just about proving a platform for socialization and learning, however.We also see the Metaverse as a powerful tool for promoting positive change in the world.By bringing people together in a shared digital space, we hope to foster a greater sense of empathy, understanding, and compassion for others, helping to break down barriers and promote greater social and cultural harmony.Overall, our vision for the Metaverse is one of inclusion, collaboration, and shared purpose.We believe that by providing a global platform for connection and engagement, we can help to create a better world for all.";

  return (
    <>
      <section className="bg-cover bg-center pb-[90px]  flex flex-col items-center  bg-home-hero relative">
        
        <div className="bg-black opacity-60 absolute inset-0 z-0"></div>
        <Navbar />
        <div className="z-10 container mx-auto md:w-full w-11/12  md:pb-[180px] md:pt-[120px] pt-[80px] pb-[50px]">
        <div className="text-white text-center flex flex-col items-center">
          <h1 className="md:text-[70px] text-2xl font-extrabold font-ebrimabold md:pb-10 pb-6">
            Global Asset University
            </h1>
            {showFullContent ? null : (<p className="md:text-xl text-base md:max-w-[801px] md:leading-[48px] leading-[27px] font-ebrima max-w-[339px]">{content.split('. ')[0]}</p>)}
            {showFullContent ? (<p className="md:text-xl text-base md:max-w-[801px] md:leading-[48px] leading-[27px] font-ebrima max-w-[339px]">{content}</p>) : (<p className='cursor-pointer md:text-xl text-base md:max-w-[801px] md:leading-[48px] leading-[27px] font-ebrima max-w-[339px]' onClick={() => setShowFullContent(true)}>
              {'See more....'}
            </p>)}
            {showFullContent && (
              <p className='cursor-pointer md:text-xl text-base md:max-w-[801px] md:leading-[48px] leading-[27px] font-ebrima max-w-[339px]' onClick={() => setShowFullContent(false)}>
                {'See less'}
              </p>
            )}
            <div className="flex flex-col md:flex-row items-center md:mt-[90px] mt-[50px] md:gap-[50px] gap-[25px]">
             <Link
                to="/login"
                className="md:py-5 py-3 md:w-[261px] w-[149px] flex items-center justify-center rounded-[50px]  bg-gradient-to-r from-[#CD23E4] via-[#808080] to-[#23E42B] md:text-xl text-base text-white font-extrabold font-ebrimabold"
            >
              Get Started 
            </Link>
            <Link
              to="/"
                className="md:py-5 py-3 md:w-[261px] w-[149px] flex items-center md:border-[3px] border-[2px] border-white justify-center rounded-[50px] bg-transparent md:text-xl text-base text-white font-extrabold font-ebrimabold"
            >
              Learn More
            </Link>
          </div>
        </div>
        </div>
      </section>
    </>
  );
};

export default Hero;
