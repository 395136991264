import React, { useContext, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { FcGoogle } from "react-icons/fc";
import * as Icon from "react-icons/fi";
import { FaFacebookSquare, FaAngleLeft } from "react-icons/fa";
import { InputField, } from "../../components/reuseables/Input";
import Checkbox from "react-custom-checkbox";
import ThemeContext from "../../context/ThemeContext";
import sideBg from "../../img/sideBg.svg";
import { alertActions } from "../../actions/AlertActions";
import { loginUser } from "../api";

function LoginPage() {
  const { themeMode } = useContext(ThemeContext);

  const navigate = useNavigate();

  const handleGoBack = () => {
    navigate(-1)
  };

  const [formData, setFormData] = useState({
    email: '',
    password: '',

  });

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    let isValid = true;
    if (!formData.email) {
      alertActions.error("Please enter your Email Address");
      isValid = false;
    }
    if (!formData.password) {
      alertActions.error("Please enter a password ");
      isValid = false;
    } if (isValid) {
      loginNewUser();
    }
  };

  const loginNewUser = async () => {
    const data = { email: formData.email, password: formData.password };
    loginUser(data)
      .then((res) => {
        // (res?.data?.data?.message);
        console.log(res?.data?.message)
        alertActions.success(res?.data?.message);
      })
      .catch((error) => {

        alertActions.error(error?.response?.data?.message);
      });
  };
  return (
    <section className={`App ${themeMode}`}>
      <div className="page-bg-light  dark:page-bg-dark dark:bg-[#0c0c0cfa] bg-[#F8F8FB]">
        <div className="container mx-auto py-[55px] md:py-[80px]">
          <div className="w-full py-2 flex items-end justify-end">
            <div className="rounded-[12px] bg-black dark:bg-white py-4 px-6 text-white dark:text-black font-ebrima text-xl flex items-center justify-center cursor-pointer" onClick={handleGoBack}>
              <FaAngleLeft /> <span className="ml-2">Go Back</span>
            </div>
          </div>
          <div className="grid md:grid-cols-1 lg:grid-cols-2 items-start gap-x-[100px] px-[16px]">
            <div className="md:w-full h-[138px] md:h-[80%] bg-gradient-to-r from-[#CD23E4] via-[#808080] to-[#23E42B] rounded-[30px] p-[3px]">
              <div className="h-full w-full flex flex-col justify-center items-center rounded-[30px] dark:bg-[#0C0C0C] bg-[#F8F8FB] p-[5px]">
                <img
                  src={sideBg}
                  alt="Login"
                  className="h-full w-full object-cover rounded-[30px]"
                />
              </div>
            </div>
            <div className="md:w-full">
              <h1 className="text-[#23E42B] mt-[35px] md:mt-0 text-[18px] md:text-[35px] text-center font-ebrimabold ebrima">
                Welcome Back
              </h1>
              <p className="dark:text-white text-black text-base md:text-[22px] text-center pt-[10px]">
                Don't have an account?{" "}
                <Link to="/sign-up" className="text-[#23E42B]">
                  Sign up
                </Link>
              </p>
              {/* 
              <div className="flex justify-between gap-x-[30px] mt-[50px]">
                <div className="w-full md:max-w-[317px] h-[50px] md:h-[70px] bg-gradient-to-r from-[#CD23E4] via-[#808080] to-[#23E42B] rounded-[30px] p-[2px]">
                  <div className="h-full w-full rounded-[30px] dark:bg-[#0C0C0C] bg-[#F8F8FB] p-2 flex justify-center items-center">
                    <div className="dark:text-white text-black flex items-center justify-center text-[12px] md:text-[22px] p-[6px] md:py-[14px] md:px-[18px]">
                      Login with Google <FcGoogle size={30} className="pl-2" />
                    </div>
                  </div>
                </div>
                <div className="w-full md:max-w-[317px] h-[50px] md:h-[70px] bg-gradient-to-r from-[#CD23E4] via-[#808080] to-[#23E42B] rounded-[30px] p-[2px]">
                  <div className="h-full w-full rounded-[30px] dark:bg-[#0C0C0C] bg-[#F8F8FB] p-2 flex justify-center items-center overflow-hidden">
                    <div className="dark:text-white text-black flex items-center justify-center text-[12px] md:text-[22px] p-[6px] md:py-[14px] md:px-[18px]">
                      Login with facebook
                      <FaFacebookSquare
                        size={30}
                        color={"#2F7EE0"}
                        className="pl-2"
                      />
                    </div>
                  </div>
                </div>
              </div> */}

              <form onSubmit={handleSubmit}>
                <div className="mt-[30px] md:mt-[74px]">
                  <label
                    htmlFor="email"
                    className="dark:text-white text-black text-base md:text-[20px]"
                  >
                    Email Address
                  </label>
                  <div className="w-full bg-gradient-to-r from-[#CD23E4] via-[#808080] to-[#23E42B] rounded-[30px] p-[2px] mt-[10px] md:mt-[18px]">

                    <InputField
                      type="email"
                      placeholder="Enter a valid email address"
                      name="email"
                      value={formData.email}
                      onChange={handleInputChange}
                    />
                  </div>
                </div>
                <div className="mt-[20px] md:mt-[30px]">
                  <label
                    htmlFor="password"
                    className="dark:text-white text-black text-base md:text-[20px] "
                  >
                    Password
                  </label>
                  <div className="w-full bg-gradient-to-r from-[#CD23E4] via-[#808080] to-[#23E42B] rounded-[30px] p-[2px] mt-[10px] md:mt-[18px]">
                    <InputField
                      type="password"
                      placeholder="Enter a password you can remember"
                      name="password"
                      value={formData.password}
                      onChange={handleInputChange}
                    />
                  </div>
                </div>

                <div className="flex justify-between mt-[23px] md:mt-[40px]">
                  <div className="text-sm md:text-[20px] dark:text-white text-black">
                    <Checkbox
                      icon={<Icon.FiCheck color="#174A41" size={14} />}
                      name="my-input"
                      checked={true}
                      onChange={(value, event) => {
                        let p = {
                          isTrue: value,
                        };
                        console.log(p);
                      }}
                      borderColor="#D9D9D9"
                      style={{ cursor: "pointer" }}
                      labelStyle={{ marginLeft: 5, userSelect: "none" }}
                      label="Save Password"
                    />
                  </div>
                  <div className="text-[#23E42B] text-sm md:text-[20px]">
                    <Link to="/forgot-password">Forgot password?</Link>
                  </div>
                </div>

                <div className="flex justify-center mt-[50px] md:mt-[58px]">
                  <button className="button py-[8px] md:py-[16px] focus:outline-none">Log in</button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>

    </section>
  );
}

export default LoginPage;
