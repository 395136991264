import React, {useState} from 'react'

const OptionField = () => {
	
	const [active, setActive] = useState('')
		return (
			<div className='flex '>
				<h3 className='text-black dark:text-white md:text-[24px] font-normal'>1</h3>
				<div className='ml-[54px]'>
					<h3 className='text-black dark:text-white md:text-[24px] font-normal pb-[10px]'>
						What is an NFT?
					</h3>
					<div>
					<div className='p-[5px] rounded-[20px] bg-white flex items-center my-3 cursor-pointer'>
							<div className='bg-black rounded-[15px] h-[45px] w-[45px] text-white flex items-center justify-center md:text-[20px]'>
							A
							</div>
							<h3 className='text-black md:text-[20px] font-normal pl-6 pr-[80px]'>Non-Fungible Token</h3>
				 </div>
						
					</div>
				</div>
				</div>
		)
}

export default OptionField