import axios from "axios";

const BASE_URL = `https://global-asset.onrender.com`;

export const createUser = async ({ fullname, email, phone, password }) => {
  const url = `${BASE_URL}/api/auth/register`;
  const data = {
    fullname: fullname,
    email: email,
    phone: phone,
    password: password,
  };

  const res = await axios.post(url, data);
  return res;
};

export const loginUser = async ({ email, password }) => {
  const url = `${BASE_URL}/api/auth/login`;
  const data = {
    email: email,
    password: password,
  };

  const res = await axios.post(url, data);
  return res;
};
