import React, { useContext } from "react";
import ThemeContext from "../../../context/ThemeContext";
import RowCardNew from "./RowCardNew";
import { collections } from "../../../data/collections";

const AboutCollections = () => {
  const { themeMode } = useContext(ThemeContext);

  return (
    <>
      <section className="md:py-[100px] py-[50px] relative overflow-hidden">
        <div className=" mx-auto md:w-full w-11/12">
          <div
            className="w-full flex flex-col items-center mb-3"
            data-aos="fade-up"
          >
            <h3 className="font-extrabold font-ebrimabold  md:text-5xl text-xl text-center">
              <span
                className={`${
                  themeMode === "dark"
                    ? "text-black text_outline_white"
                    : "text_outline_black text-white"
                }`}
              >
                About Our
              </span>{" "}
              <span className="dark:text-white text-black">Collections</span>
            </h3>
          </div>
          <div className="relative z-5">
            {collections.map((collection) => {
              return <RowCardNew
                data={collection}
                key={collection.id}
              />;
            })}
          </div>
        </div>
      </section>
    </>
  );
};

export default AboutCollections;
