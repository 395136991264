import React, { useContext } from "react";
import ThemeContext from "../../../context/ThemeContext";
import Slider from "react-slick";
import { Link } from "react-router-dom";
import { CircleBtn } from "../../../components/reuseables/Buttons";
import TrendingCard from "./TrendingCards";
import { trending } from "../../../data/trending";

const SpotlightSlider = ({ id }) => {

  const { themeMode } = useContext(ThemeContext);

  const PrevArrow = (props) => {
    const { onClick } = props;
    return (
      <div
        className="custom-prev-arrow cursor-pointer absolute z-20 md:top-[-135px] top-[410px] left-[30%] md:left-[85%]"
        onClick={onClick}
      >
        <CircleBtn type="prev" fade={true} />
      </div>
    );
  };

  const NextArrow = (props) => {
    const { onClick } = props;
    return (
      <div
        className="custom-next-arrow cursor-pointer absolute z-20 md:top-[-135px] top-[410px] right-[30%] md:right-[15px]"
        onClick={onClick}
      >
        <CircleBtn type="next" />
      </div>
    );
  };

  const settings = {
    className: "center relative",
    infinite: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    swipeToSlide: true,
    prevArrow: <PrevArrow />,
    nextArrow: <NextArrow />,
    responsive: [
      {
        // Breakpoint for mobile devices
        breakpoint: 976,
        settings: {
          slidesToShow: 1,
          // slidesToScroll: 1,
          centerMode: true,
          centerPadding: "60px",
        },
      },
    ],
  };
  return (
    <>
     
      <section
        className={` md:pt-[100px] pb-[120px] py-[50px] relative ${

          id === 2
            ? null
            : `overflow-hidden `
        } `}
      >
      
        <div className=" mx-auto md:w-full ">
          {id === 2 ? null : (
            <div
              className="w-full flex flex-col items-center"
              data-aos="fade-up"
            >
              <h3 className="font-extrabold font-ebrimabold md:text-5xl text-xl text-center">
                <span
                  className={`${
                    themeMode === "dark"
                      ? "text-black text_outline_white"
                      : "text_outline_black text-white"
                  }`}
                >
                  Our
                </span>{" "}
                <span className="dark:text-white text-black">Collections</span>
              </h3>
              <p className="font-normal font-ebrima md:w-[900px] w-[324px] dark:text-white text-black md:leading-[48px] leading-[27px] py-6 text-center md:text-xl text-base">
                Our platform is home to some of the world's most innovative and
                valuable digital assets, From rare collectibles to one-of-a-kind
                works of art, our collection is constantly growing and changing.
              </p>
            </div>
          )}

          <div className="mt-[20px] md:mt-[90px] mx-[5px] md:mx-0">
            <div className="md:flex hidden items-center mt-[20px] md:mb-[70px] mb-[10px] w-11/12 md:w-full mx-auto md:px-20 px-5">
              <div className="flex items-center">
                <div className="md:w-[15px] md:h-[15px] h-[9px] w-[9px] rounded-full bg-[#23E42B] animate-ping"></div>
                <h3 className="text-black ml-3 dark:text-white md:text-[40px] text-base">
                  <span className="font-extrabold font-ebrimabold">
                    Trending
                  </span>{" "}
                  <span className="font-normal font-ebrima">Collections</span>
                </h3>
              </div>
              <Link
                to="/"
                className="text-[#808080] font-extrabold font-ebrimabold md:text-lg text-sm ml-5 mt-2"
              >
                View All
              </Link>
            </div>
            <div className="w-full flex items-center justify-between px-3 mx-auto md:hidden">
              <div className="flex items-center">
                <div className="md:w-[15px] md:h-[15px] h-[9px] w-[9px] rounded-full bg-[#23E42B] animate-ping"></div>
                <h3 className="text-black ml-3 dark:text-white md:text-[40px] text-base">
                  <span className="font-extrabold font-ebrimabold">
                    Trending
                  </span>{" "}
                  <span className="font-normal font-ebrima">Collections</span>
                </h3>
              </div>
              <Link
                to="/"
                className="text-[#808080] font-extrabold font-ebrimabold md:text-lg text-sm ml-5 mt-2"
              >
                View All
              </Link>
            </div>
            <div className="w-full md:px-20 mt-8">
              <Slider {...settings}>
                {trending.map((trend) => (
                  <div className="first:ml-[78px] md:first:ml-[90px]" key={trend.id}>
                    <TrendingCard img={trend.image} title={trend.title} />
                  </div>
                ))}
              </Slider>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default SpotlightSlider;
