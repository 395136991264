import { Routes, Route } from "react-router-dom";
import "./index.css";
import "./App.css";
import HomePage from "./pages/home/index";
import NFT from "./pages/nft";
import Learn from "./pages/learn";
import LoginPage from "./pages/log-in";
import { ThemeProvider } from "./context/ThemeContext";
import { NavbarContextProvider } from "./context/NavbarContext";
import { TabContextProvider } from "./context/TabContext";
import SignUp from "./pages/sign-up";
import BlogPage from "./pages/blog";
import ForgotPassword from "./pages/ForgotPassword";
import ComingSoon from "./pages/ComingSoon";
import Preloader from "./components/reuseables/Preloader"
import AOS from "aos";
import "aos/dist/aos.css";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import About from "./pages/about-us";
import Test from "./pages/question";



const App = () => {
  AOS.init();
  return (
    <>
      <ThemeProvider>
        <NavbarContextProvider>
          <TabContextProvider>
            <Preloader />
            <Routes>
              <Route path="/" element={<HomePage />} />
              <Route path="/about-us" element={<About />} />
              <Route path="/dashboard/test" element={<Test />} />
              <Route path="/nft" element={<NFT />} />
              <Route path="/learn" element={<Learn />} />
              <Route path="/login" element={<LoginPage />} />
              <Route path="/sign-up" element={<SignUp />} />
              <Route path="/blog" element={<BlogPage />} />
              <Route path="/event" element={<BlogPage />} />
              <Route path="/forgot-password" element={<ForgotPassword />} />
              <Route path="/coming-soon" element={<ComingSoon />} />
            </Routes>
          </TabContextProvider>
        </NavbarContextProvider>
      </ThemeProvider>
      <ToastContainer className={"mx-auto"} theme="colored" />
    </>
  );
};

export default App;
