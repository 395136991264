import React, { useContext } from "react";
import Navbar from "../../components/reuseables/Navbar";
import Footer from "../../components/reuseables/Footer";
import ThemeContext from "../../context/ThemeContext";
import Collections from "../home/components/TrendingCollections";
import { trending, tokens, males, females, hands, student } from "../../data/trending";
import CardSlider from "./components/CardSlider";
import Hero from "./components/Hero";
import NftSlide from "./components/NftSlider";
import TabContext from "../../context/TabContext";

const NFT = () => {
  const { themeMode } = useContext(ThemeContext);
  const { activeTab } = useContext(TabContext);


  const All = () => {
    return (
      <>
        <div className="page-bg-light dark:page-bg-dark">
        <NftSlide datas={trending} />
      
          <Collections id={2} />
          <CardSlider
            title="TOKEN"
            datas={tokens}
            id={1}
          />
          <CardSlider
            title="STUDENT ID"
            datas={student}
          />
          <CardSlider
            title="HANDS"
            datas={hands}
          />
          <CardSlider
            title="MALE"
            datas={males}
          />
          <CardSlider
            title="FEMALE"
            datas={females}
          />
        </div>
      </>
    )
  }

  const tabs = [
    {
      name: "All",
      component: <All />,
      id: 0
    },
    {
      name: "Token",
      component: <CardSlider
        title="TOKEN"
        datas={tokens}
      />,
      id: 1
    },
    {
      name: "Student ID",
      component: <CardSlider
        title="STUDENT ID"
        datas={student}
      />,
      id: 2
    },
    {
      name: "Hands",
      component: <CardSlider
        title="HANDS"
        datas={hands}
      />,
      id: 3
    },
    {
      name: "Male",
      component: <CardSlider
        title="MALE"
        datas={males}
      />,
      id: 4
    },
    {
      name: "Female",
      component: <CardSlider
        title="FEMALE"
        datas={females}
      />,
      id: 5
    }
  ];

  return (
    <div className={`App ${themeMode}`}>

      <Navbar id={2} />
      <div className="page-bg-light dark:page-bg-dark dark:bg-[#0c0c0cfa] bg-[#F8F8FB]">
      <Hero />

     
        {tabs.map(tab => {
          if (activeTab === tab.id) {
            return tab.component;
          }
        })}
    
        <div className="pt-[100px]" />
      </div>
      <Footer />
    </div>
  );
};

export default NFT;
