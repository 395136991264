import hand from '../img/collections/hand.svg'
import student from "../img/collections/student.svg";
import token from "../img/collections/token.svg";
import male from "../img/collections/male.svg";
import female from "../img/collections/female.svg";

export const collections = [
  {
    id: 1,
    image: hand,
    video:
      "https://user-images.githubusercontent.com/43154722/227202160-f0535742-f624-456e-82c3-2064bc4a0cbd.mp4",
    tag: "video",
    title: "Hands Of Wealth",
    description:
      "The Hands of Wealth is a unique concept that promises to bring abundance to global asset universe. the idea behind it is to create a community of individuals with rare and valuable skills, knowledge, and expertise, who contributed to the growth and development of global asset ecosystem.These rarest hands will bring value to your Metaverse experience, by helping you to navigate the complex world of digital assets and unlocking new opportunities for growth and prosperity. Whether you are a seasoned investor or just getting started in the world of digital assets, the Hands of Wealth community can provide you with valuable insights and resource to help you succeed.The Hands of Wealth is a collaborative and inclusive community that welcome individuals from all walks of life, with a diverse range of backgrounds and experiences. By bringing together and best and brightest minds in the asset ecosystem, the Hands of Wealth promise to create a new paradigm of abundance and prosperity for all.",
  },
  {
    id: 2,
    image: student,
    video:
      "https://user-images.githubusercontent.com/43154722/227200574-40ba4bf7-4e3f-4836-82c9-f20522065037.mp4",
    tag: "video",
    title: "Student ID",
    description:
      "Student IDs are revolutionary concept that promise to provide students with a range of exciting new opportunities and experiences in Metaverse. By holding a students ID, student will gain access to range of exclusive events and virtual experiences, including free virtual concerts, private meet-ups in both physical and virtual worlds, and financial literacy courses.In addition to these exclusive events, students will also gain access to a social media platform that will allow them to connect with other students in the Metaverse, share experiences, and engage in range of different online activities.This platform will offer a range of online experiences that are designed to help students develop their skills, explore new interests, and expand their horizons while it is not necessary to hold a student ID to access the website, most parts of the Metaverse will require a student ID to access the website, most parts of the Metaverse will require a student ID for access. This is because the student ID is a critical part of the verification process, ensuring that only students have access to the exclusive events and experiences that are available.Overall, student IDs are a game-changing innovation that promises to transform the way students engage with the Metaverse. By providing access to range of exciting new opportunities and experiences, student will be able to develop their skills, connect with other students, and expand their horizons in ways that were previously impossible. ",
  },
  {
    id: 3,
    image: token,
    video:
      "https://user-images.githubusercontent.com/43154722/227201922-99908a3e-a9b2-4da4-92b1-d9b64a804673.mp4",
    tag: "video",
    title: "Token",
    description:
      "Global Asset Tokens (GAU) are revolutionary concept that promises to transform the world of gaming and digital asset. With GAU, players will be able to make purchases and sell product in the Metaverse 24/7, using a single, unified currency that is accepted both in the physical world and in the digital realm.One of the key benefit of Gau is the ability to provide players with discounts on products sold, making it easier and more affordable to access the products and experiences they want.In addition to its use as currency for in-game purchases, GAU is also required to purchase NFTs in game. NFTs, or non-fungible tokens, are unique digital assets that represent ownership of specific item or experience within the game. By using GAU to purchase NFTs, players can ensure that they own unique Valuable assets that can bw used to enhance their gaming experience.Overall, Global Asset Tokens represent an exciting new frontier in the world of gaming and digital asset. By providing a single, unified currency for in-game purchases and discounts, as well as the ability to purchase unique NFTs, GAU promises to transform the way players engage with the Metaverse, providing a more seamless and enjoyable experience for players around the world.",
  },
  {
    id: 4,
    image: male,
    tag: "image",
    title: "Male",
    description:
      "Male and Female Global are the avatars for the Global Asset Universe Metaverse experience. They provide a personalized identity for users within the Metaverse, allowing them to create and customize their own unique character to use in the digital world.Using your Global avatar, you can explore the Metaverse, walk around the campus socialize with other users, attend virtual concerts, and join different educational meet-ups. Globals will also be required to participate in some of the games available within the Metaverse.One of the exciting features of Globals is that they can be GPT (Generative Pre-trained over time. This means that as you use your Global avatar more, it will become smarter and more responsive to your commands and actions, providing an even more immersive and personalized experience.Overall, the Male and Female Globals are an integral part of the Global Asset Universe Metaverse experience, providing users with a unique and customizable identity within the digital world. Whether  you are attending virtual concerts, socializing with other users, or participating in game and educational meet-ups, your Global avatar will be your gateway to a world of exiting new experiences and Opportunities.",
  },
  {
    id: 5,
    image: female,
    tag: "image",
    title: "Female",
    description:
      "Male and Female Global are the avatars for the Global Asset Universe Metaverse experience. They provide a personalized identity for users within the Metaverse, allowing them to create and customize their own unique character to use in the digital world.Using your Global avatar, you can explore the Metaverse, walk around the campus socialize with other users, attend virtual concerts, and join different educational meet-ups. Globals will also be required to participate in some of the games available within the Metaverse.One of the exciting features of Globals is that they can be GPT (Generative Pre-trained over time. This means that as you use your Global avatar more, it will become smarter and more responsive to your commands and actions, providing an even more immersive and personalized experience.Overall, the Male and Female Globals are an integral part of the Global Asset Universe Metaverse experience, providing users with a unique and customizable identity within the digital world. Whether  you are attending virtual concerts, socializing with other users, or participating in game and educational meet-ups, your Global avatar will be your gateway to a world of exiting new experiences and Opportunities.",
  },
];