import React, { useState, useEffect, useContext } from 'react';
import ThemeContext from "../../context/ThemeContext";


function Preloader() {
	const { themeMode } = useContext(ThemeContext);

	const [isActive,
		setIsActive] = useState(true);
	const [close,
		setClose] = useState(false);

	useEffect(() => {
		setTimeout(() => {
			setIsActive(false)
		}, 4000);
	}, [])


	setTimeout(() => {
		setClose(true)
	}, 4500)

	return (
		<div className={`${themeMode} ${close && 'close'}`}>
			<div className={`${isActive ? '' : 'fadeOut'} h-screen  page-bg-light  dark:page-bg-dark dark:bg-[#0c0c0cfb] bg-[#F8F8FB] bg-no-repeat w-full bg-cover flex items-center justify-center fixed z-site`}>

			
					<div className="preloader">
						<svg viewBox="0 0 102 102" fill="none" xmlns="http://www.w3.org/2000/svg">
							<path className="big-circle" d="M101 51C101 78.6142 78.6142 101 51 101C23.3858 101 1 78.6142 1 51" stroke="#cd23e4" strokeWidth="2" />
							<path className="small-circle" d="M91 51C91 28.9086 73.0914 11 51 11C28.9086 11 11 28.9086 11 51" stroke="#23e42b" strokeWidth="2" />
						</svg>
					
				</div>

			</div>
		</div>
	)
}

export default Preloader