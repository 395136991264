import { FaAngleLeft, FaAngleRight } from 'react-icons/fa';


export const CircleBtn = props => {

	const  {type, fade = false } = props
	return (
		<div className={`md:h-[62px] md:w-[62px] w-[36px] h-[36px] rounded-[50%] flex items-center justify-center md:mx-4 mx-3 cursor-pointer ${fade ? `bg-[#4E4E4E]` : `bg-black dark:bg-white`}`}>
			{type === 'next' ? <FaAngleRight className='text-white dark:text-black md:text-2xl text-xl' /> : <FaAngleLeft className='text-white md:text-2xl text-xl' />}
			</div>
	)
}


export const RoundBtn = props => {
	const { icon,} = props;
	const IconComponent = icon;

	return (
		<div className={`md:h-[62px] md:w-[62px] w-[36px] h-[36px] rounded-[50%] flex items-center justify-center md:mx-4 mx-3 cursor-pointer bg-white border-2 dark:border-[#808080]`}>
			<IconComponent size={35} className='text-black md:text-2xl text-xl' /> 
		</div>
	)
}

