import React from "react";

const TrendingCard = (props) => {
  return (
    <>
      <div
        className="bg-gradient-to-r from-[#CD23E4] via-[#808080] to-[#23E42B] md:h-[500px] md:w-[96%] w-[97%] h-[350px] md:rounded-[30px] rounded-[20px] overflow-hidden md:p-[3px] p-[1.8px] flex items-center justify-center md:mr-8 mr-4 spotlight-card z-50"
        data-aos="flip-down"
        data-aos-duration={`2000` + props.id}
      >
        <div className="p-1 dark:bg-[#0C0C0C] bg-[#F8F8FB] h-full w-full md:rounded-[30px] rounded-[20px] flex flex-col items-center justify-center relative overflow-hidden">
          <div className="h-full w-full md:rounded-[20px] rounded-[15px] md:border-[7px] border-[5px] dark:border-[#0C0C0C] border-[#F8F8FB] absolute">
            <img
              src={props.img}
              alt="Collection"
              className="h-full w-full object-cover md:rounded-[20px] rounded-[15px]"
            />
            {props.type === 2 ? (
              <div className="md:py-4 py-2 px-6 element-blur bg-black bg-opacity-40 z-10 absolute w-full bottom-0 overflow-hidden rounded-bl-[20px] rounded-br-[20px] flex flex-col items-start justify-start">
                <h4 className="font-ebrimabold md:text-xl text-base font-extrabold leading-[24px] text-white">
                  {props.title}
                </h4>
                <h3 className="md:text-xl text-base leading-[24px] pt-1 text-white">
                  Floor price:{" "}
                  <span className="font-extrabold font-ebrimabold">
                    0.14 ETH
                  </span>
                </h3>
              </div>
            ) : (
              <div className="md:py-3 py-3 text-white element-blur z-10 absolute w-full bottom-0 overflow-hidden rounded-bl-[20px] rounded-br-[20px]">
                <h4 className="font-ebrimabold md:text-xl text-base font-extrabold leading-[24px] text-center">
                  {props.title}
                </h4>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default TrendingCard;
