import React from "react";

const NftCard = (props) => {
  return (
    <>
      <div className="md:h-[500px] md:w-full h-[350px] rounded-[20px] overflow-hidden p-[3px] flex items-center justify-center spotlight-card w-full">
        <div className="p-1 dark:bg-[#0C0C0C] bg-[#F8F8FB] h-full w-full rounded-[20px] flex flex-col items-center justify-center relative overflow-hidden">
          <div className="h-full w-full rounded-[20px] border-[10px] dark:border-[#0C0C0C] border-[#F8F8FB] absolute">
            <img
              src={props.img}
              alt="Collection"
              className="h-full w-full object-cover rounded-[20px]"
            />
            <div className="md:py-3 py-2 px-6 element-blur bg-black bg-opacity-40 z-10 absolute w-full bottom-0 overflow-hidden rounded-bl-[20px] rounded-br-[20px] flex flex-col items-start justify-start">
              <h4 className="font-ebrimabold md:text-xl text-base font-extrabold leading-[24px] text-white">
                {props.title}
              </h4>
              <h3 className="md:text-xl text-base leading-[24px] pb-[5px] text-white">
                Floor price:{" "}
                <span className="font-extrabold font-ebrimabold">0.14 ETH</span>
              </h3>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default NftCard;
