import React, { useContext } from "react";
import ThemeContext from "../../context/ThemeContext";
import Hero from "./components/Hero";
import TrendingCollections from "./components/TrendingCollections";
import AboutCollections from "./components/AboutCollections";
import Footer from "../../components/reuseables/Footer";
import Sidebar from "../../components/reuseables/Sidebar";

function HomePage() {
  const { themeMode } = useContext(ThemeContext);

  return (
    <div className={`App ${themeMode}`}>
      <div className="flex justify-between gap-0">
        <div className="w-[60px] md:block hidden">
          <Sidebar />
        </div>

        <div className="w-full ">
          <Hero />
          <div className="page-bg-light dark:page-bg-dark dark:bg-[#0c0c0cfa] bg-[#F8F8FB] bg-no-repeat overflow-hidden">
            <TrendingCollections />
            <AboutCollections />
          </div>
          <Footer />
        </div>
      </div>
    </div>
  );
}

export default HomePage;
