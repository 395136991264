import { createContext, useState } from "react";

const NavbarContext = createContext();

export function NavbarContextProvider({ children }) {
  const [showMenu, setShowMenu] = useState(false);

  //   show menu navbar
  const toggleMenu = () => {
    setShowMenu(!showMenu);
  };

  const closeMenu = () => {
    setShowMenu(false);
  };

  return (
    <NavbarContext.Provider
      value={{
        toggleMenu,
        closeMenu,
        showMenu,
      }}
    >
      {children}
    </NavbarContext.Provider>
  );
}

export default NavbarContext;
