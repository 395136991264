import React, { useContext } from "react";
import Nav from "./components/Nav";
import Welcome from "./components/Welcome";
import Hero from "./components/Hero";
import ThemeContext from "../../context/ThemeContext";
import Footer from "../../components/reuseables/Footer";
import RealEstate from "./components/RealEstate";
import Credit from "./components/Credit";
import DigitalAsset from "./components/DigitalAsset";
import Crypto from "./components/Crypto";
import BlockChain from "./components/Blockchain";
import Metaverse from "./components/Metaverse";
import NFTs from "./components/NFTs";
import Web3 from "./components/Web3";
import AI from "./components/AI";

const Learn = () => {
  const { themeMode } = useContext(ThemeContext);
  return (
    <section className={`App ${themeMode}`}>
      <div className="w-full page-bg-light  dark:page-bg-dark dark:bg-[#0c0c0cfa] bg-[#F8F8FB] bg-no-repeat">
        <Nav />
        <Hero />
        <Welcome />
      
          <RealEstate />
          <Credit />
          <Crypto />
          <BlockChain />
          <Metaverse />
          <NFTs />
          <Web3 />
          <DigitalAsset />


          <AI />
   
        <Footer />
      </div>
    </section>
  );
};

export default Learn;
