import React, { useEffect, useRef } from 'react';

const CollectionCard = ({ data }) => {
	const videoRef = useRef(null);

	useEffect(() => {
		if (videoRef.current) {
			videoRef.current.play();
		}
	}, [videoRef]);

	if (!data) {
		return null;
	}

	if (data.video) {
		return (
			<div className='bg-gradient-to-r from-[#CD23E4] via-[#808080] to-[#23E42B] h-full w-full rounded-[30px] overflow-hidden p-[2px] flex items-center justify-center z-30'>
				<div className='md:p-2 p-1.5 dark:bg-[#0C0C0C] bg-[#F8F8FB] h-full w-full rounded-[30px] flex items-center justify-center overflow-hidden'>
					<video
						ref={videoRef}
						autoPlay
						muted
						loop
						playsInline
						className="h-full w-full object-cover rounded-[20px]"
					>
						<source src={data.video} type="video/mp4" />
					</video>
				</div>
			</div>
		);
	}

	return (
		<div className='bg-gradient-to-r from-[#CD23E4] via-[#808080] to-[#23E42B] h-full w-full rounded-[30px] overflow-hidden p-[2px] flex items-center justify-center z-30'>
			<div className='md:p-2 p-1.5 dark:bg-[#0C0C0C] bg-[#F8F8FB] h-full w-full rounded-[30px] flex items-center justify-center overflow-hidden'>
				<img src={data.image} alt='Collection' className='h-full w-full object-cover rounded-[20px]' />
			</div>
		</div>
	);
}

export default CollectionCard;

