import React, { useContext } from "react";
import {Link} from "react-router-dom"
import ThemeContext from "../context/ThemeContext";
import { ForgotEmailAddress } from "../components/reuseables/Input";

function ForgotPassword() {
  const { themeMode } = useContext(ThemeContext);
  return (
    <section className={`App ${themeMode}`}>
      <div className="page-bg-light  dark:page-bg-dark dark:bg-[#0c0c0cfa] bg-[#F8F8FB] h-screen">
        <div className=" px-[16px] md:px-0 flex flex-col items-center justify-center h-screen">
          <h1 className="text-center text-[#23E42B] text-[2rem] md:text-[3rem] font-ebrimabold">
            Forgot Password?
          </h1>
          <p className="text-[16px] md:text-[1.3rem] dark:text-white text-black w-full max-w-[814px] text-center">
            Enter the email address associated with your account and we will
            send you a code with instructions to reset your password.{" "}
          </p>

          <form className="max-w-[834px] w-full mx-auto">
            <div className="mt-[28px]">
              <label
                htmlFor="email"
                className="dark:text-white text-black text-base md:text-[24px]"
              >
                Email Address
              </label>
              <div className="w-[100%] bg-gradient-to-r from-[#CD23E4] via-[#808080] to-[#23E42B] rounded-[30px] p-[3px] mt-[10px] md:mt-[18px]">
                <ForgotEmailAddress />
              </div>
            </div>
            <div className="mx-auto w-full max-w-[500px] flex justify-center mt-[30px] md:mt-[50px]">
              <button className="button py-[8px] md:py-[16px] focus:outline-none font-ebrimabold text-[1.3rem]">
                Submit
              </button>
            </div>
          </form>
          <div className="md:mt-[38px] mt-[20px] flex justify-start items-center text-[18px] md:text-[20px] dark:text-white text-black">
          Go back to 
              <Link to="/login" className="text-[#23E42B] ml-1">
              Login page
              </Link>
          </div>
        </div>
      </div>
    </section>
  );
}

export default ForgotPassword;
