import { createContext, useState } from "react";

const TabContext = createContext();

export function TabContextProvider({ children }) {
const [activeTab, setActive] = useState(0);

  //   show menu navbar
  const toggleTab = (tabId) => {
    setActive(tabId);
  };

 

  return (
    <TabContext.Provider
      value={{
					toggleTab,
							activeTab
      }}
    >
      {children}
    </TabContext.Provider>
  );
}

export default TabContext;
