import React, { useContext } from "react";
import { Link, useLocation } from "react-router-dom";
import { FiMenu } from "react-icons/fi";
import { HiSun, HiMoon } from "react-icons/hi";
import logo from "../../img/logo.png";
import NavbarContext from "../../context/NavbarContext";
import MobileMenu from "./MobileMenu";
import ThemeContext from "../../context/ThemeContext";

const Navbar = ({ id }) => {
  const { themeMode, switchTheme } = useContext(ThemeContext);
  // Using navbar context
  const { showMenu, toggleMenu } = useContext(NavbarContext);
  //assigning location variable
  const location = useLocation();

  //destructuring pathname from location
  const { pathname } = location;

  //Javascript split method to get the name of the path in array
  const splitLocation = pathname.split("/");

  return (
    <>
      {showMenu && <MobileMenu />}
      <section
        className={`md:px-0 py-6 z-30 w-full ${
          id === 2 ? `bg-black` : `bg-transparent`
        }`}
      >
        <div className="md:px-20 px-0 mx-auto md:w-full w-11/12">
          <div className="flex items-center justify-between w-full">
            <div className="logo">
              <Link to="/">
                <img
                  src={logo}
                  alt="Logo"
                  className="md:h-[80px] md:w-[90px] h-[60px] w-[60px] pointer-events-none"
                />
              </Link>
            </div>
            <ul className="items-center hidden sm:flex ">
              <li className={`px-4 mx-2 cursor-pointer font-bold`}>
                <Link
                  to="/"
                  className={`text-xl font-ebrimabold ${
                    splitLocation[1] === ""
                      ? `text-[#23E42B] font-extrabold`
                      : `font-extrabold text-white`
                  }`}
                >
                  Home
                </Link>
              </li>
              <li className={`px-4 mx-2 cursor-pointer font-extrabold`}>
                <Link
                  to="/nft"
                  className={`text-xl font-ebrimabold ${
                    splitLocation[1] === "nft"
                      ? `text-[#23E42B] font-extrabold`
                      : `font-extrabold text-white`
                  }`}
                >
                  NFT
                </Link>
              </li>
              <li className={`px-4 mx-2 cursor-pointer font-extrabold`}>
                <Link
                  to="/coming-soon"
                  className={`text-xl font-ebrimabold ${
                    splitLocation[1] === "coming-soon"
                      ? `text-[#23E42B] font-extrabold`
                      : `font-extrabold text-white`
                  }`}
                >
                  Metaverse
                </Link>
              </li>
              <li className={`px-4 mx-2 cursor-pointer font-extrabold`}>
                <Link
                  to="/learn"
                  className={`text-xl font-ebrimabold ${
                    splitLocation[1] === "learn"
                      ? `text-[#23E42B] font-extrabold`
                      : `font-extrabold text-white`
                  }`}
                >
                  Learn
                </Link>
              </li>
              <li className={`px-4 mx-2 cursor-pointer font-extrabold`}>
                <Link
                  to="/event"
                  className={`text-xl font-ebrimabold ${
                    splitLocation[1] === "event"
                      ? `text-[#23E42B] font-extrabold`
                      : `font-extrabold text-white`
                  }`}
                >
                  Event
                </Link>
              </li>
              <li className={`px-4 mx-2 cursor-pointer font-extrabold`}>
                <Link
                  to="/about-us"
                  className={`text-xl font-ebrimabold ${
                    splitLocation[1] === "about-us"
                      ? `text-[#23E42B] font-extrabold`
                      : `font-extrabold text-white`
                  }`}
                >
                  About Us
                </Link>
              </li>
              <li
                className={`px-4 mx-2 cursor-pointer font-extrabold `}
                onClick={switchTheme}
                title={`${themeMode} Mode`}
              >
                {themeMode === "light" ? (
                  <div className="h-[45px] w-[45px] bg-[#fff] rounded-[50%] flex items-center justify-center">
                    <HiMoon size={30} color="#000" />
                  </div>
                ) : (
                  <div className="h-[45px] w-[45px] bg-[#fff] rounded-[50%] flex items-center justify-center">
                    <HiSun size={30} color="#000" />
                  </div>
                )}
              </li>
            </ul>
            <div className="md:hidden flex items-center">
              <div
                className={`px-4 mx-3 cursor-pointer font-extrabold `}
                onClick={switchTheme}
                title={`${themeMode} Mode`}
              >
                {themeMode === "light" ? (
                  <div className="h-[30px] w-[30px] bg-[#fff] rounded-[50%] flex items-center justify-center">
                    <HiMoon size={20} color="#000" />
                  </div>
                ) : (
                  <div className="h-[30px] w-[30px] bg-[#fff] rounded-[50%] flex items-center justify-center">
                    <HiSun size={20} color="#000" />
                  </div>
                )}
              </div>
              <div
                className="bg-white h-[40px] w-[40px] rounded-[50%] flex items-center justify-center md:hidden cursor-pointer"
                onClick={toggleMenu}
              >
                {" "}
                <FiMenu size={20} />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Navbar;
