import React from "react";
import { Link } from "react-router-dom";
import {FaFacebook, FaInstagram, FaTwitter, FaDiscord, FaRegUserCircle} from "react-icons/fa"
import logo from "../../../img/logo.png";
import { AiFillTwitterCircle, AiOutlineInstagram, AiFillLinkedin, AiFillYoutube, AiFillRedditCircle } from "react-icons/ai";

function Navbar() {
  return (
    <div className="bg-black">
      <div className="container mx-auto py-[28px]">
        <div className="flex justify-between items-center px-[16px]">
          <Link to="/" className="flex items-center ">
            <div className="w-[56px] h-[56px] md:w-[81px] md:h-[81px] ">
            <img src={logo} alt="Logo" className="w-full"/>
            </div>
            <h1 className="font-ebrimabold text-[18px] md:text-[36px] text-white align-baseline">
              Global asset <span className="text-[#23E42B]">Event</span>
            </h1>
          </Link>
          <ul className="flex gap-x-[40px]">
            <li className="cursor-pointer hidden md:block">
              <Link to="https://www.youtube.com/channel/UC3ULOmjYOjBvwnQGsg8sagQ" target="_blank">
                <AiFillYoutube size={40} color={"#ffffff"} />
              </Link>
              </li>
            <li className="md:flex md:justify-center md:items-center rounded-[159px] p-2 bg-white cursor-pointer hidden">
              <Link to="https://www.linkedin.com/in/will-stanley-104202243/" target="_blank">
                <AiFillLinkedin size={25} color={"#000"} className="w-full" />
              </Link>
              </li>
            <li className="hidden md:flex md:justify-center md:items-center rounded-[159px] p-2 bg-white cursor-pointer">
              <Link to="https://twitter.com/globalassetu" target="_blank">
                <AiFillTwitterCircle size={25} color={"#000"} className="w-full" />
              </Link>
              </li>
            <li className="cursor-pointer hidden md:block">
              <Link to="https://discord.gg/zdRZpHwa" target="_blank">
                <FaDiscord size={40} color={"#ffffff"} />
              </Link>
            </li>
            <li className="cursor-pointer hidden md:block">
              <Link to="https://www.reddit.com/user/Globalassetuniverse" target="_blank">
                <AiFillRedditCircle size={40} color={"#ffffff"} />
              </Link>
            </li>
              <li className="cursor-pointer">
                <FaRegUserCircle size={40} color={"#ffffff"}/>
              </li>
          </ul>
        </div>
      </div>
    </div>
  );
}

export default Navbar;
