import React, { useContext, useState, useEffect } from "react";
import Navbar from '../../components/reuseables/Navbar'
import Footer from '../../components/reuseables/Footer'
import ThemeContext from "../../context/ThemeContext";

function About() {
	const { themeMode } = useContext(ThemeContext);
		return (
			<section className={`App ${themeMode}`}>
				<div className="w-full">
					<Navbar id={2} />
					<div className="h-full page-bg-light  dark:page-bg-dark dark:bg-[#0c0c0cfa] bg-[#F8F8FB] bg-no-repeat w-full bg-cover ">
						<div className="md:px-[90px] px-[20px] mx-auto w-full h-full ">
							<div className="w-full h-full pt-[80px]">
								<h3 className="font-ebrimabold md:text-[40px] text-[#23E42B] font-extrabold">Welcome to Global Asset University </h3>
								<div className="mt-[40px] w-full md:max-w-[80%]">
									<p className="md:text-[24px] dark:text-white text-black font-normal font-inter leading-[40px] pb-[100px]">Thank you for joining us on this journey.<br /><br />

										As a member of our community, we’d like you to experience the best of blockchain innovation without the
										needless complexity or jargon. You’ll get access to a next-gen crypto exchange and mobile banking app, for
										savings, investments and payments. And yet, hi is not just an app.<br /><br />

										hi will act as your gateway to Web3, including wealth, social networking, entertainment and retail services, which
										you can experience both in the real world and the Metaverse.<br /><br />

										You’ll become a holder of our cryptocurrency - HI - which powers the hi ecosystem. You can think of it as a
										membership token. Just by holding HI, you’ll get <span className="underline">benefits</span>. Be that a free digital subscription, best rates at 5* hotels
										around the world, or “interest boosters” so you can earn up to 30% in APY. In contrast to financial institutions, we
										aim to make money for you, not off you.<br /><br />

										Transparency and security runs through our company DNA. We regularly publish a <span className="underline">report</span> on our website with the
										most important numbers relating to our token, finance and community. Most of them are also available on the
										blockchain.<br /><br />

										You will get to know our team. Our leaders come from global, billion-dollar companies including Crypto.com,
										Morgan-Stanley and Tencent. We have 100+ employees in offices across Europe and Asia. We’re always on the
										lookout for fresh, bright minds - come and <span className="underline">join us</span>.<br /><br />

										An important network of organizations contribute to our vision. We have received a Virtual Currency Service
										Provider (VCSP) license from the Lithuanian government and four of the world’s leading blockchain and innovation
										funds are firmly behind us.<br /><br />

										In the true spirit of Web3, we embrace co-creation. You’ll have the opportunity to help us design and refine new
										products and services, and as our community matures hi will transition to a DAO, meaning that governance for the
										largest blockchain ecosystem in the world will be transferred to token holders.<br /><br />




										We hope you’ll stay with us for the ride,<br /><br />


										Let’s get hi together!<br /><br />


										The hi Team</p>
								</div>
							</div>
							
</div>
					</div>
					<Footer />
				</div>
				</section>
		)
}

export default About