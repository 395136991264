import realEstateImg1 from "../img/real-estate1.svg";
import realEstateImg2 from "../img/real-estate2.svg";
import realEstateImg3 from "../img/real-estate3.svg";
import creditImg1 from "../img/creditImg1.svg";
import creditImg2 from "../img/creditImg2.svg";
import creditImg3 from "../img/creditImg3.svg";
import creditImg4 from "../img/creditImg4.svg";
import cryptoImg1 from "../img/cryptoImg1.svg";
import cryptoImg2 from "../img/cryptoImg2.svg";
import cryptoImg3 from "../img/cryptoImg3.svg";
import cryptoImg4 from "../img/cryptoImg4.svg";
import BTImg1 from "../img/BTImg1.svg";
import BTImg2 from "../img/BTImg2.svg";
import BTImg3 from "../img/BTImg3.svg";
import BTImg4 from "../img/BTImg4.svg";
import metaverseImg1 from "../img/metaverseImg1.svg";
import metaverseImg2 from "../img/metaverseImg2.svg";
import metaverseImg3 from "../img/metaverseImg3.svg";
import metaverseImg4 from "../img/metaverseImg4.svg";
import NFTImg1 from "../img/NFTImg1.svg";
import NFTImg2 from "../img/NFTImg2.svg";
import NFTImg3 from "../img/NFTImg3.svg";
import NFTImg4 from "../img/NFTImg4.svg";
import web3Img1 from "../img/web3Img1.svg";
import web3Img2 from "../img/web3Img2.svg";
import web3Img3 from "../img/web3Img3.svg";
import web3Img4 from "../img/web3Img4.svg";
import digitalAssetImg1 from "../img/digitalAssetImg1.svg";
import digitalAssetImg2 from "../img/digitalAssetImg2.svg";
import digitalAssetImg3 from "../img/digitalAssetImg3.svg";
import digitalAssetImg4 from "../img/digitalAssetImg4.svg";
import AIImg1 from "../img/AIImg1.svg";
import AIImg2 from "../img/AIImg2.svg";
import AIImg3 from "../img/AIImg3.svg";
import AIImg4 from "../img/AIImg4.svg";

export const realEstate = [
  {
    id: 1,
    image: realEstateImg1,
    title: "Overview of Real Estate",
  },
  {
    id: 2,
    image: realEstateImg2,
    title: "Real Estate Tokenization",
  },
  {
    id: 3,
    image: realEstateImg3,
    title: "Real Estate Investment",
  },
  {
    id: 4,
    image: realEstateImg1,
    title: "Land Title Management",
  },
  {
    id: 5,
    image: realEstateImg2,
    title: "Overview of Real Estate",
  },
  {
    id: 6,
    image: realEstateImg3,
    title: "Overview of Real Estate",
  },
];

export const credit = [
  {
    id: 1,
    image: creditImg1,
    title: "Understanding Credit Scores",
  },
  {
    id: 2,
    image: creditImg2,
    title: "Credit Report Analysis",
  },
  {
    id: 3,
    image: creditImg3,
    title: "Improving Credit Scores",
  },
  {
    id: 4,
    image: creditImg4,
    title: "Dealing With Debit and Repaying Loans",
  },
  {
    id: 5,
    image: creditImg1,
    title: "Understanding Credit ",
  },
  {
    id: 6,
    image: creditImg2,
    title: "Understanding Scores",
  },
];

export const crypto = [
  {
    id: 1,
    image: cryptoImg1,
    title: "Overview of Cryptocurrency",
  },
  {
    id: 2,
    image: cryptoImg2,
    title: "History of Cryptocurrency",
  },
  {
    id: 3,
    image: cryptoImg3,
    title: "Overview of Cryptocurrency",
  },
  {
    id: 4,
    image: cryptoImg4,
    title: "Overview of Cryptocurrency",
  },
  {
    id: 5,
    image: cryptoImg1,
    title: "Cryptocurrency Market and Trading",
  },
  {
    id: 6,
    image: cryptoImg2,
    title: "Overview of Cryptocurrency",
  },
];

export const blockChain = [
  {
    id: 1,
    image: BTImg1,
    title: "Overview of Blockchain Technology",
  },
  {
    id: 2,
    image: BTImg2,
    title: "Types of Blockchains",
  },
  {
    id: 3,
    image: BTImg3,
    title: "Blockchain Security and Privacy",
  },
  {
    id: 4,
    image: BTImg4,
    title: "Blockchain Development and Deployment",
  },
  {
    id: 5,
    image: BTImg1,
    title: "Overview of Blockchain",
  },
  {
    id: 6,
    image: BTImg2,
    title: "Overview of Technology",
  },
];

export const metaverse = [
  {
    id: 1,
    image: metaverseImg1,
    title: "Overview of Metaverse",
  },
  {
    id: 2,
    image: metaverseImg2,
    title: "Building a Metaverse",
  },
  {
    id: 3,
    image: metaverseImg3,
    title: "Virtual Real Estate Investment",
  },
  {
    id: 4,
    image: metaverseImg4,
    title: "Virtual Asset Management and Tradingy",
  },
  {
    id: 5,
    image: metaverseImg3,
    title: "Overview of Techn",
  },
  {
    id: 6,
    image: metaverseImg1,
    title: "Overview of Technology",
  },
];

export const NFT = [
  {
    id: 1,
    image: NFTImg1,
    title: "Overview of NFTs",
  },
  {
    id: 2,
    image: NFTImg2,
    title: "NFT Market and Trading",
  },
  {
    id: 3,
    image: NFTImg3,
    title: "NFT Creation and Management",
  },
  {
    id: 4,
    image: NFTImg4,
    title: "Use Cases for NFTs",
  },
  {
    id: 5,
    image: NFTImg1,
    title: "Overview of NFTs",
  },
  {
    id: 6,
    image: NFTImg3,
    title: "Overview of NFTs",
  },
];

export const web3 = [
  {
    id: 1,
    image: web3Img1,
    title: "Introduction to Web3",
  },
  {
    id: 2,
    image: web3Img2,
    title: "Decentralized Applications",
  },
  {
    id: 3,
    image: web3Img3,
    title: "Building Decentralized Applications",
  },
  {
    id: 4,
    image: web3Img4,
    title: "Web3 Tools and Platforms",
  },
  {
    id: 5,
    image: web3Img1,
    title: "Introduction Web3",
  },
  {
    id: 6,
    image: web3Img3,
    title: "Introduction to Web3",
  },
];

export const digitalAsset = [
  {
    id: 1,
    image: digitalAssetImg1,
    title: "What is digital asset",
  },
  {
    id: 2,
    image: digitalAssetImg2,
    title: "How to access digital asset",
  },
  {
    id: 3,
    image: digitalAssetImg3,
    title: "How to acquire digtal asset",
  },
  {
    id: 4,
    image: digitalAssetImg4,
    title: "More on digital asset",
  },
  {
    id: 5,
    image: digitalAssetImg1,
    title: "Digital asset",
  },
  {
    id: 6,
    image: digitalAssetImg2,
    title: "What is digital asset",
  },
];

export const ai = [
  {
    id: 1,
    image: AIImg1,
    title: "What is digital asset",
  },
  {
    id: 2,
    image: AIImg2,
    title: "What is digital asset",
  },
  {
    id: 3,
    image: AIImg3,
    title: "What is digital asset",
  },
  {
    id: 4,
    image: AIImg4,
    title: "What is digital asset",
  },
  {
    id: 5,
    image: AIImg1,
    title: "What is digital asset",
  },
  {
    id: 6,
    image: AIImg3,
    title: "What is digital asset",
  },
];
