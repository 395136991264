import React, {useEffect} from "react";

const Sidebar = () => {
  
  useEffect(() => {
    var content = document.querySelector(".content");
    var list = document.querySelector(".c-list");

    for (var i = 0; i < 50; i++) {
      var clone = content.cloneNode(true);
      list.appendChild(clone);
    }
  }, []);

  return (
    <section className="bg-white h-screen sidebar md:inline-flex hidden items-center justify-center px-5">
     <div className="c-list">
        <div className="inline-flex items-center justify-center md:mt-4 content">
          <h3 className="capitalize text-[34px] font-extrabold font-ebrimabold sidebar-text-white ">Join Our Community Now</h3>
          <h3 className="text-[24px] text-[#5959A9] font-extrabold font-ebrimabold px-14">#Global Asset University</h3>
          <div className="mt-4">
            <img src="https://user-images.githubusercontent.com/43154722/230801877-ba49eaeb-1e14-4df7-832f-52c44b80687f.svg" alt="Logo" className="h-[80px] w-[160px]" />
          </div>
        </div>
      </div>
    </section>


  );
};

export default Sidebar;
