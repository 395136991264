import React, { useRef, useState, useEffect } from "react";
import Slider from "react-slick";
import NftCard from "./NftCard";
import { RoundBtn } from "../../../components/reuseables/Buttons";
import { RiArrowRightSLine, RiArrowLeftSLine } from "react-icons/ri";

const NftSlide = ({ datas, title }) => {
  const PrevArrow = (props) => {
    const { onClick } = props;
    return (
      <div
        className="custom-prev-arrow cursor-pointer absolute z-20 md:top-[200px] top-[170px]  left-[-20px] md:left-[-40px]"
        onClick={onClick}
      >
        <RoundBtn icon={RiArrowLeftSLine} />
      </div>
    );
  };

  const NextArrow = (props) => {
    const { onClick } = props;
    return (
      <div
        className="custom-next-arrow cursor-pointer absolute z-20 md:top-[200px] top-[170px] right-[-20px] md:right-[-40px]"
        onClick={onClick}
      >
        <RoundBtn icon={RiArrowRightSLine} />
      </div>
    );
  };

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    swipeToSlide: true,
    prevArrow: <PrevArrow />,
    nextArrow: <NextArrow />,
    responsive: [
      {
        // Breakpoint for mobile devices
        breakpoint: 676,
        settings: {
          slidesToShow: 1,
          // slidesToScroll: 1,
          centerMode: true,
          centerPadding: "60px",
          // paddingHorizontal: "100px"
        },
      },
    ],
  };
  return (
    <section className="pt-10 w-full">
      <div className="mx-auto md:w-full w-full px-3 md:px-20">
        <div className="w-full">
          <Slider {...settings}>
            {datas.map((data) => (
              <div className="first:ml-[55px] md:first:ml-[90px]">
                <NftCard img={data.image} type={2} title={data.title} />
              </div>
            ))}
          </Slider>
        </div>
      </div>
    </section>
  );
};

export default NftSlide;
