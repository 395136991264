import React, { useContext } from "react";
import { Link, useLocation } from "react-router-dom";
import { RiCloseLine } from "react-icons/ri";
import NavbarContext from "../../context/NavbarContext";


// Menu Items as array
const menuItems = [
	{
		id: 1,
		name: "Home",
		label: "home",
		path: "/",
	},
	{
		id: 2,
		name: "NFT",
		label: "nft",
		path: "/nft",
	},
	{
		id: 3,
		name: "Metaverse",
		label: "metaverse",
		path: "/coming-soon",
	},
	{
		id: 4,
		name: "Learn",
		label: "learn",
		path: "/learn",
	},
	{
		id: 5,
		name: "Event",
		label: "event",
		path: "/event",
	},
	{
		id: 6,
		name: "About Us",
		label: "about-us",
		path: "/coming-soon",
	},
	{
		id: 7,
		name: "Profile",
		label: "profile",
		path: "/coming-soon",
	},
];

function MobileMenu() {
	const { closeMenu } = useContext(NavbarContext);

	//assigning location variable
	const location = useLocation();

	//destructuring pathname from location
	const { pathname } = location;

	//Javascript split method to get the name of the path in array
	const splitLocation = pathname.split("/");

	return (
		<>
			<nav className="md:hidden fixed z-50 h-screen element-blur bg-black bg-opacity-50 w-full overflow-x-hidden">
				<div className="pt-8 px-4 md:px-0 bg-bg-white">
					<div className="flex items-center justify-between  w-full">
						<div className="logo">
							<Link to="/">
								<img src="https://user-images.githubusercontent.com/43154722/230801931-24c2d5c0-7e80-4dd1-bd65-f1d328a82aca.svg" alt="Global" className="h-[60px] w-[60px] pointer-events-none" />
							</Link>
						</div>
						<div
							className="bg-white h-[40px] w-[40px] rounded-[50%] flex items-center justify-center md:hidden cursor-pointer"
							onClick={closeMenu}
						>
							{" "}
							<RiCloseLine size={25} />
						</div>
					</div>
				</div>
				<ul className="md:hidden z-50 p-0 mb-12 text-center flex flex-col items-center justify-center mx-auto w-full mt-3 ease-in duration-300 bg-scroll">
					{menuItems.map((item) => (
						<li className="my-6" key={item.id} onClick={() => { closeMenu() }}>
							<Link
								to={item.path}
								className={`no-underline px-5 text-lg font-ebrimabold font-medium  hover:scale-100 ${splitLocation[1] === item.label
										? `text-[#23E42B]`
									: `text-white`
                  }`}
							>
								{item.name}
							</Link>
						</li>
					))}
				</ul>
				
			</nav>
		</>
	);
}

export default MobileMenu;
