import React from "react";
import learnimg from "../../../img/learn-img.svg";

const Welcome = () => {
  return (
    <>
      <section className="md:py-[60px] py-[20px] w-full bg-mobile-lines md:bg-none">
        <div className="container mx-auto md:w-full w-11/12">
          <div className="flex flex-col md:flex-row items-center justify-between gap-4">
            <div>
              <h3 className="font-ebrimabold font-extrabold md:leading-[70px] md:text-[48px] text-[22px] leading-[32px] capitalize w-full md:max-w-[660px] max-w-[257px] dark:text-white text-black">
                welcome to global asset university
              </h3>
              <p className="font-ebrima font-medium md:leading-[40px] md:text-xl text-base py-[30px] w-full max-w-[630px] dark:text-white text-black">
                GAU Is aN educational platform focused  primarily on the understanding of ASSETS. GAU will combine Education, Social Media , Real Estate & Entertainment in one Global METAVERSE experience. Our community of GLOBALS will take over the World by accumulating and buying each asset at a time.
              </p>
            </div>
            <div className="md:h-[700px] md:w-[900px] h-[350px] object-fit">
              <img
                src={learnimg}
                alt="Learn Img"
                className="h-full w-full object-fit"
              />
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Welcome;
