import React, { useContext } from "react";
import logo from "../../../img/logo.png";
import logo2 from "../../../img/global-asset-logo.png"
import ThemeContext from "../../../context/ThemeContext";

const lists = ["Product", "Company", "Community", "Creator"];

const cards = [
  {
    id: 1,
    image: logo,
    lists: ["Community", "Trust and Safety"],
    title: "January 2023 Product Update",
    date: "February 14, 2023",
    description:
      "At GlobalAsset, we’re constantly taking steps to improve trust and safety in the NFT space and ensure users feel confident connecting with us in all of our community channels.",
  },

  {
    id: 2,
    image: logo2,
    lists: ["Community", "Trust and Safety"],
    title: "January 2023 Product Update",
    date: "February 14, 2023",
    description:
      "At GlobalAsset, we’re constantly taking steps to improve trust and safety in the NFT space and ensure users feel confident connecting with us in all of our community channels.",
  },
  {
    id: 3,
    image: logo,
    lists: ["Community", "Trust and Safety"],
    title: "January 2023 Product Update",
    date: "February 14, 2023",
    description:
      "At GlobalAsset, we’re constantly taking steps to improve trust and safety in the NFT space and ensure users feel confident connecting with us in all of our community channels.",
  },
];

function Category() {
  const { themeMode } = useContext(ThemeContext);
  return (
    <section
      className={`py-[30px] ${themeMode}`}
    >
      <div className="container mx-auto px-[16px] md:px-0">
        <h3 className="text-center font-ebrimabold text-[24px] md:text-[48px] dark:text-white text-black">
          Categories
        </h3>
        <ul className="mt-[40px] flex flex-wrap items-center justify-center gap-[20px]">
          {lists.map((list) => (
            <li
              key={list}
              className="dark:text-white text-black bg-[#CECECE]/80 dark:bg-[#3E3E3E] text-base md:text-[20px] px-[16px] py-2 md:py-[15px] md:px-[40px] rounded-[50px]"
            >
              {list}
            </li>
          ))}
        </ul>

        <div className="flex flex-col items-center md:flex-row md:justify-between md:mb-[90px] gap-y-[48px] md:gap-y-0 md:gap-x-[48px] mt-[64px]">
          {cards.map((item) => (
            <div
              key={item.id}
              className="cat-card max-w-[492px] w-full rounded-[15px] md:rounded-[30px] dark:bg-[#3E3E3E] bg-white "
            >
              <div className="card-image md:h-[230px] h-[200px] flex justify-center items-center">
                <img src={item.image} alt="logo" className="object-contain md:w-[120px] md:h-[150px] h-[100px]" />
              </div>

              <div className="p-[10px] md:p-[30px]">
              <ul className="flex justify-start gap-x-[10px] md:gap-x-[20px]">
              {item.lists.map((item) => (
                <li key={item} className="bg-gradient-to-r from-[#CD23E4] via-[#808080] to-[#23E42B] px-[15px] py-[6px] md:py-[10px] md:px-[20px] text-[14px] text-base text-white rounded-[50px] ">
                  {item}
                </li>
              ))}
              </ul>

              <h1 className="font-ebrimabold text-[24px] mt-[20px] dark:text-white text-black">{item.title}</h1>
              <p className="font-semibold text-base md:text-[20px] mt-[20px] dark:text-white text-black">
                {item.date}
              </p>

              <p className="text-base md:text-[18px] mt-[20px] dark:text-white text-black leading-[25px] md:leading-[32px]">{item.description}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
}

export default Category;
