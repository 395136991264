
import { RiSearchLine } from "react-icons/ri";

export function InputField({type, placeholder, value, onChange, name}) {
  return (
    <div className="h-full w-full rounded-[30px] dark:bg-[#0C0C0C] bg-[#F8F8FB] flex justify-center items-center">
      <input
        type={type}
        placeholder={placeholder}
        className="focus:outline-none py-2 px-4 md:p-4 text-[18px] login-border w-full dark:bg-[#0C0C0C] bg-[#F8F8FB] dark:text-white text-black"
        value={value}
        name={name}
        onChange={onChange}
      />
    </div>
  );
}

export function PasswordInput() {
  return (
    <div className="h-full w-full rounded-[30px] dark:bg-[#0C0C0C] bg-[#F8F8FB]flex justify-center items-center">
      <input
        type="password"
        id="password"
        placeholder="Enter password"
        className="focus:outline-none py-2 px-4 md:p-4 text-[18px] login-border w-full dark:bg-[#0C0C0C] bg-[#F8F8FB] dark:text-white text-black"
      />
    </div>
  );
}

export function PasswordInput2() {
  return (
    <div className="h-full w-full rounded-[30px] dark:bg-[#0C0C0C] bg-[#F8F8FB]flex justify-center items-center">
      <input
        type="password"
        id="password"
        placeholder="Confirm password"
        className="focus:outline-none py-2 px-4 md:p-4 text-[18px] login-border w-full dark:bg-[#0C0C0C] bg-[#F8F8FB] dark:text-white text-black"
      />
    </div>
  );
}

export function PhoneNumber() {
  return (
    <div className="h-full w-full rounded-[30px] dark:bg-[#0C0C0C] bg-[#F8F8FB]flex justify-center items-center">
      <input
        type="text"
        id="text"
        placeholder="Enter your phone number"
        className="focus:outline-none py-2 px-4 md:p-4 text-[18px] login-border w-full dark:bg-[#0C0C0C] bg-[#F8F8FB] dark:text-white text-black"
      />
    </div>
  );
}

export function SearchBox({ placeholder }) {
  return (
    <div className="md:w-[800px] w-full rounded-[50px] py-3 px-5 dark:bg-[#CECECE]/60 flex items-center">
      <RiSearchLine size={30} className="text-black dark:text-white" />
      <input
        type="text"
        className="w-full py-2 px-4 bg-transparent text-black dark:text-white md:text-xl focus:outline-none"
        placeholder={placeholder}
      />
    </div>
  );
}

export function ForgotEmailAddress() {
  return (
    <div className="h-full w-full rounded-[30px] flex justify-center items-center">
      <input
        type="email"
        id="email"
        placeholder="Enter your email address"
        className="focus:outline-none py-2 px-4 md:p-4 text-[18px] login-border w-full dark:bg-[#0C0C0C] bg-[#F8F8FB] dark:text-white text-black"
      />
    </div>
  );
}
