import token from "../img/collections/token.svg";
import male from "../img/collections/male.svg";
import female from "../img/collections/female.svg";

export const trending = [
  {
    id: 1,
    image:
      "https://user-images.githubusercontent.com/43154722/229288527-7c2e01ff-fb82-4057-b934-56b20816d2db.png",
    title: "SKEGEE VERSE",
  },
  {
    id: 2,
    image:
      "https://user-images.githubusercontent.com/43154722/228738390-8a210e1d-f7e7-441e-bc40-e2a01f6ab208.png",
    title: "ROSE GOLD",
  },
  {
    id: 3,
    image:
      "https://user-images.githubusercontent.com/43154722/229288310-375ec9db-b4d5-4a3f-83f3-37521298ad94.png",
    title: "XU VERSE",
  },
  {
    id: 4,
    image: token,
    title: "TOKEN",
  },
  {
    id: 5,
    image:
      "https://user-images.githubusercontent.com/43154722/230185892-c74babf7-6e96-41bc-9f4e-3bd8e905ec7a.png",
    title: "DIAMOND ON GOLD",
  },
  {
    id: 6,
    image:
      "https://user-images.githubusercontent.com/43154722/229718997-a92d0bff-24d2-443c-93b3-973e2a46db2f.png",
    title: "NCC VERSE",
  },

  {
    id: 7,
    image:
      "https://user-images.githubusercontent.com/43154722/228738926-5861a769-0a6f-42ee-aa7d-6c81e90fc2aa.png",
    title: "CHROME",
  },

  {
    id: 8,
    image:
      "https://user-images.githubusercontent.com/43154722/229288564-f31339f1-d4f7-4c38-ac17-60c02aa0e960.png",
    title: "HU VERSE",
  },
  {
    id: 9,
    image: token,
    title: "TOKEN",
  },
  {
    id: 10,
    image:
      "https://user-images.githubusercontent.com/43154722/230185522-a699ffbc-15ed-4f13-ad5b-c6cf647358a9.png",
    title: "NATURE",
  },
  {
    id: 11,
    image:
      "https://user-images.githubusercontent.com/43154722/230080342-3adc106c-36b2-4d7b-932a-62fc2d482559.png",
    title: "SPELL HOUSE VERSE",
  },

  {
    id: 12,
    image:
      "https://user-images.githubusercontent.com/43154722/229288717-d3def0f6-4882-4883-856f-4dac2c42b6c8.png",
    title: "AGGIE VERSE",
  },
  {
    id: 13,
    image:
      "https://user-images.githubusercontent.com/43154722/230185757-36fdcffd-1dbe-47f3-9945-0b82bf8bb98a.png",
    title: "LEATHER",
  },

  {
    id: 14,
    image:
      "https://user-images.githubusercontent.com/43154722/229288527-7c2e01ff-fb82-4057-b934-56b20816d2db.png",
    title: "SKEGEE VERSE",
  },
  {
    id: 15,
    image:
      "https://user-images.githubusercontent.com/43154722/230185648-3c8ae2da-dcd9-443b-bd8e-39f5e0cdb68d.png",
    title: "MAT",
  },

  {
    id: 16,
    image: token,
    title: "TOKEN",
  },
  {
    id: 17,
    image:
      "https://user-images.githubusercontent.com/43154722/230798281-2a1bcc9f-6c13-4a16-9176-107011232a51.png",
    title: "JSU VERSE",
  },
  {
    id: 18,
    image:
      "https://user-images.githubusercontent.com/43154722/228739088-13b212b8-ff9f-44bc-ae84-865d084010f1.png",
    title: "DIAMOND",
  },

  {
    id: 19,
    image:
      "https://user-images.githubusercontent.com/43154722/229719938-9b47f35c-b82a-4b58-ba28-c2608588ae02.png",
    title: "ASU VERSE",
  },
  {
    id: 20,
    image:
      "https://user-images.githubusercontent.com/43154722/228739230-f1eb0d2c-a5ac-4536-a148-13d06fdcdf1d.png",
    title: "IRIDESCENT",
  },
  {
    id: 21,
    image:
      "https://user-images.githubusercontent.com/43154722/229288527-7c2e01ff-fb82-4057-b934-56b20816d2db.png",
    title: "SKEGEE VERSE",
  },
  {
    id: 22,
    image:
      "https://user-images.githubusercontent.com/43154722/228738390-8a210e1d-f7e7-441e-bc40-e2a01f6ab208.png",
    title: "ROSE GOLD",
  },
  {
    id: 23,
    image:
      "https://user-images.githubusercontent.com/43154722/229288310-375ec9db-b4d5-4a3f-83f3-37521298ad94.png",
    title: "XU VERSE",
  },
  {
    id: 24,
    image: token,
    title: "TOKEN",
  },
  {
    id: 25,
    image:
      "https://user-images.githubusercontent.com/43154722/230185892-c74babf7-6e96-41bc-9f4e-3bd8e905ec7a.png",
    title: "DIAMOND ON GOLD",
  },
  {
    id: 26,
    image:
      "https://user-images.githubusercontent.com/43154722/229718997-a92d0bff-24d2-443c-93b3-973e2a46db2f.png",
    title: "NCC VERSE",
  },

  {
    id: 27,
    image:
      "https://user-images.githubusercontent.com/43154722/228738926-5861a769-0a6f-42ee-aa7d-6c81e90fc2aa.png",
    title: "CHROME",
  },

  {
    id: 28,
    image:
      "https://user-images.githubusercontent.com/43154722/229288564-f31339f1-d4f7-4c38-ac17-60c02aa0e960.png",
    title: "HU VERSE",
  },
  {
    id: 29,
    image: token,
    title: "TOKEN",
  },
  {
    id: 30,
    image:
      "https://user-images.githubusercontent.com/43154722/230185522-a699ffbc-15ed-4f13-ad5b-c6cf647358a9.png",
    title: "NATURE",
  },
  {
    id: 31,
    image:
      "https://user-images.githubusercontent.com/43154722/230080342-3adc106c-36b2-4d7b-932a-62fc2d482559.png",
    title: "SPELL HOUSE VERSE",
  },

  {
    id: 32,
    image:
      "https://user-images.githubusercontent.com/43154722/229288717-d3def0f6-4882-4883-856f-4dac2c42b6c8.png",
    title: "AGGIE VERSE",
  },
  {
    id: 33,
    image:
      "https://user-images.githubusercontent.com/43154722/230185757-36fdcffd-1dbe-47f3-9945-0b82bf8bb98a.png",
    title: "LEATHER",
  },

  {
    id: 34,
    image:
      "https://user-images.githubusercontent.com/43154722/229288527-7c2e01ff-fb82-4057-b934-56b20816d2db.png",
    title: "SKEGEE VERSE",
  },
  {
    id: 35,
    image:
      "https://user-images.githubusercontent.com/43154722/230185648-3c8ae2da-dcd9-443b-bd8e-39f5e0cdb68d.png",
    title: "MAT",
  },

  {
    id: 36,
    image: token,
    title: "TOKEN",
  },
  {
    id: 37,
    image:
      "https://user-images.githubusercontent.com/43154722/230798281-2a1bcc9f-6c13-4a16-9176-107011232a51.png",
    title: "JSU VERSE",
  },
  {
    id: 38,
    image:
      "https://user-images.githubusercontent.com/43154722/228739088-13b212b8-ff9f-44bc-ae84-865d084010f1.png",
    title: "DIAMOND",
  },

  {
    id: 39,
    image:
      "https://user-images.githubusercontent.com/43154722/229719938-9b47f35c-b82a-4b58-ba28-c2608588ae02.png",
    title: "ASU VERSE",
  },
  {
    id: 40,
    image:
      "https://user-images.githubusercontent.com/43154722/228739230-f1eb0d2c-a5ac-4536-a148-13d06fdcdf1d.png",
    title: "IRIDESCENT",
  },
  {
    id: 41,
    image:
      "https://user-images.githubusercontent.com/43154722/229288527-7c2e01ff-fb82-4057-b934-56b20816d2db.png",
    title: "SKEGEE VERSE",
  },
  {
    id: 42,
    image:
      "https://user-images.githubusercontent.com/43154722/228738390-8a210e1d-f7e7-441e-bc40-e2a01f6ab208.png",
    title: "ROSE GOLD",
  },
  {
    id: 43,
    image:
      "https://user-images.githubusercontent.com/43154722/229288310-375ec9db-b4d5-4a3f-83f3-37521298ad94.png",
    title: "XU VERSE",
  },
  {
    id: 44,
    image: token,
    title: "TOKEN",
  },
  {
    id: 45,
    image:
      "https://user-images.githubusercontent.com/43154722/230185892-c74babf7-6e96-41bc-9f4e-3bd8e905ec7a.png",
    title: "DIAMOND ON GOLD",
  },
  {
    id: 46,
    image:
      "https://user-images.githubusercontent.com/43154722/229718997-a92d0bff-24d2-443c-93b3-973e2a46db2f.png",
    title: "NCC VERSE",
  },

  {
    id: 47,
    image:
      "https://user-images.githubusercontent.com/43154722/228738926-5861a769-0a6f-42ee-aa7d-6c81e90fc2aa.png",
    title: "CHROME",
  },

  {
    id: 48,
    image:
      "https://user-images.githubusercontent.com/43154722/229288564-f31339f1-d4f7-4c38-ac17-60c02aa0e960.png",
    title: "HU VERSE",
  },
  {
    id: 49,
    image: token,
    title: "TOKEN",
  },
  {
    id: 50,
    image:
      "https://user-images.githubusercontent.com/43154722/230185522-a699ffbc-15ed-4f13-ad5b-c6cf647358a9.png",
    title: "NATURE",
  },
  {
    id: 51,
    image:
      "https://user-images.githubusercontent.com/43154722/230080342-3adc106c-36b2-4d7b-932a-62fc2d482559.png",
    title: "SPELL HOUSE VERSE",
  },

  {
    id: 52,
    image:
      "https://user-images.githubusercontent.com/43154722/229288717-d3def0f6-4882-4883-856f-4dac2c42b6c8.png",
    title: "AGGIE VERSE",
  },
  {
    id: 53,
    image:
      "https://user-images.githubusercontent.com/43154722/230185757-36fdcffd-1dbe-47f3-9945-0b82bf8bb98a.png",
    title: "LEATHER",
  },

  {
    id: 54,
    image:
      "https://user-images.githubusercontent.com/43154722/229288527-7c2e01ff-fb82-4057-b934-56b20816d2db.png",
    title: "SKEGEE VERSE",
  },
  {
    id: 55,
    image:
      "https://user-images.githubusercontent.com/43154722/230185648-3c8ae2da-dcd9-443b-bd8e-39f5e0cdb68d.png",
    title: "MAT",
  },

  {
    id: 56,
    image: token,
    title: "TOKEN",
  },
  {
    id: 57,
    image:
      "https://user-images.githubusercontent.com/43154722/230798281-2a1bcc9f-6c13-4a16-9176-107011232a51.png",
    title: "JSU VERSE",
  },
  {
    id: 58,
    image:
      "https://user-images.githubusercontent.com/43154722/228739088-13b212b8-ff9f-44bc-ae84-865d084010f1.png",
    title: "DIAMOND",
  },

  {
    id: 59,
    image:
      "https://user-images.githubusercontent.com/43154722/229719938-9b47f35c-b82a-4b58-ba28-c2608588ae02.png",
    title: "ASU VERSE",
  },
  {
    id: 60,
    image:
      "https://user-images.githubusercontent.com/43154722/228739230-f1eb0d2c-a5ac-4536-a148-13d06fdcdf1d.png",
    title: "IRIDESCENT",
  },
];

export const tokens = [
  {
    id: 1,
    image: token,
    title: "Token",
  },
  {
    id: 2,
    image: token,
    title: "Token",
  },
  {
    id: 3,
    image: token,
    title: "Token",
  },
  {
    id: 4,
    image: token,
    title: "Token",
  },
  {
    id: 5,
    image: token,
    title: "Token",
  },
  {
    id: 6,
    image: token,
    title: "Token",
  },
  {
    id: 7,
    image: token,
    title: "Token",
  },
  {
    id: 8,
    image: token,
    title: "Token",
  },
  {
    id: 9,
    image: token,
    title: "Token",
  },
  {
    id: 10,
    image: token,
    title: "Token",
  },
  {
    id: 11,
    image: token,
    title: "Token",
  },
  {
    id: 12,
    image: token,
    title: "Token",
  },
  {
    id: 13,
    image: token,
    title: "Token",
  },
  {
    id: 14,
    image: token,
    title: "Token",
  },
  {
    id: 15,
    image: token,
    title: "Token",
  },
  {
    id: 16,
    image: token,
    title: "Token",
  },
  {
    id: 17,
    image: token,
    title: "Token",
  },
  {
    id: 18,
    image: token,
    title: "Token",
  },
  {
    id: 19,
    image: token,
    title: "Token",
  },
  {
    id: 20,
    image: token,
    title: "Token",
  },
  {
    id: 21,
    image: token,
    title: "Token",
  },
  {
    id: 22,
    image: token,
    title: "Token",
  },
  {
    id: 23,
    image: token,
    title: "Token",
  },
  {
    id: 24,
    image: token,
    title: "Token",
  },
];

export const males = [
  {
    id: 1,
    image: male,
    title: "Coming Soon",
  },
  {
    id: 2,
    image: male,
    title: "Coming Soon",
  },
  {
    id: 3,
    image: male,
    title: "Coming Soon",
  },
  {
    id: 4,
    image: male,
    title: "Coming Soon",
  },
  {
    id: 5,
    image: male,
    title: "Coming Soon",
  },
  {
    id: 6,
    image: male,
    title: "Coming Soon",
  },
  {
    id: 7,
    image: male,
    title: "Coming Soon",
  },
  {
    id: 8,
    image: male,
    title: "Coming Soon",
  },
  {
    id: 9,
    image: male,
    title: "Coming Soon",
  },
  {
    id: 10,
    image: male,
    title: "Coming Soon",
  },
  {
    id: 11,
    image: male,
    title: "Coming Soon",
  },
  {
    id: 12,
    image: male,
    title: "Coming Soon",
  },
  {
    id: 13,
    image: male,
    title: "Coming Soon",
  },
  {
    id: 14,
    image: male,
    title: "Coming Soon",
  },
  {
    id: 15,
    image: male,
    title: "Coming Soon",
  },
  {
    id: 16,
    image: male,
    title: "Coming Soon",
  },
  {
    id: 17,
    image: male,
    title: "Coming Soon",
  },
  {
    id: 18,
    image: male,
    title: "Coming Soon",
  },
  {
    id: 19,
    image: male,
    title: "Coming Soon",
  },
  {
    id: 20,
    image: male,
    title: "Coming Soon",
  },
  {
    id: 21,
    image: male,
    title: "Coming Soon",
  },
  {
    id: 22,
    image: male,
    title: "Coming Soon",
  },
  {
    id: 23,
    image: male,
    title: "Coming Soon",
  },
  {
    id: 24,
    image: male,
    title: "Coming Soon",
  },
  {
    id: 25,
    image: male,
    title: "Coming Soon",
  },
  {
    id: 26,
    image: male,
    title: "Coming Soon",
  },
  {
    id: 27,
    image: male,
    title: "Coming Soon",
  },
];

export const females = [
  {
    id: 1,
    image: female,
    title: "Coming Soon",
  },
  {
    id: 2,
    image: female,
    title: "Coming Soon",
  },
  {
    id: 3,
    image: female,
    title: "Coming Soon",
  },
  {
    id: 4,
    image: female,
    title: "Coming Soon",
  },
  {
    id: 5,
    image: female,
    title: "Coming Soon",
  },
  {
    id: 6,
    image: female,
    title: "Coming Soon",
  },
  {
    id: 7,
    image: female,
    title: "Coming Soon",
  },
  {
    id: 8,
    image: female,
    title: "Coming Soon",
  },
  {
    id: 9,
    image: female,
    title: "Coming Soon",
  },
  {
    id: 10,
    image: female,
    title: "Coming Soon",
  },
  {
    id: 11,
    image: female,
    title: "Coming Soon",
  },
  {
    id: 12,
    image: female,
    title: "Coming Soon",
  },
  {
    id: 13,
    image: female,
    title: "Coming Soon",
  },
  {
    id: 14,
    image: female,
    title: "Coming Soon",
  },
  {
    id: 15,
    image: female,
    title: "Coming Soon",
  },
  {
    id: 16,
    image: female,
    title: "Coming Soon",
  },
  {
    id: 17,
    image: female,
    title: "Coming Soon",
  },
  {
    id: 18,
    image: female,
    title: "Coming Soon",
  },
  {
    id: 19,
    image: female,
    title: "Coming Soon",
  },
  {
    id: 20,
    image: female,
    title: "Coming Soon",
  },
  {
    id: 22,
    image: female,
    title: "Coming Soon",
  },
  {
    id: 23,
    image: female,
    title: "Coming Soon",
  },
  {
    id: 24,
    image: female,
    title: "Coming Soon",
  },
  {
    id: 25,
    image: female,
    title: "Coming Soon",
  },
  {
    id: 26,
    image: female,
    title: "Coming Soon",
  },
  {
    id: 27,
    image: female,
    title: "Coming Soon",
  },
  {
    id: 28,
    image: female,
    title: "Coming Soon",
  },
];

export const hands = [
  {
    id: 1,
    image:
      "https://user-images.githubusercontent.com/43154722/228738390-8a210e1d-f7e7-441e-bc40-e2a01f6ab208.png",
    title: "ROSE GOLD",
  },
  {
    id: 2,
    image:
      "https://user-images.githubusercontent.com/43154722/228738926-5861a769-0a6f-42ee-aa7d-6c81e90fc2aa.png",
    title: "CHROME",
  },
  {
    id: 3,
    image:
      "https://user-images.githubusercontent.com/43154722/228739088-13b212b8-ff9f-44bc-ae84-865d084010f1.png",
    title: "DIAMOND",
  },
  {
    id: 4,
    image:
      "https://user-images.githubusercontent.com/43154722/228739230-f1eb0d2c-a5ac-4536-a148-13d06fdcdf1d.png",
    title: "IRIDESCENT",
  },
  {
    id: 5,
    image:
      "https://user-images.githubusercontent.com/43154722/230185391-69883bb1-5241-4512-a750-c46fa1cb5cc2.png",
    title: "TILE WOOD",
  },
  {
    id: 6,
    image:
      "https://user-images.githubusercontent.com/43154722/230185522-a699ffbc-15ed-4f13-ad5b-c6cf647358a9.png",
    title: "NATURE",
  },
  {
    id: 7,
    image:
      "https://user-images.githubusercontent.com/43154722/230185648-3c8ae2da-dcd9-443b-bd8e-39f5e0cdb68d.png",
    title: "MAT",
  },
  {
    id: 8,
    image:
      "https://user-images.githubusercontent.com/43154722/230185757-36fdcffd-1dbe-47f3-9945-0b82bf8bb98a.png",
    title: "LEATHER",
  },
  {
    id: 9,
    image:
      "https://user-images.githubusercontent.com/43154722/230185892-c74babf7-6e96-41bc-9f4e-3bd8e905ec7a.png",
    title: "DIAMOND ON GOLD",
  },
];

export const student = [
  {
    id: 1,
    image:
      "https://user-images.githubusercontent.com/43154722/229288310-375ec9db-b4d5-4a3f-83f3-37521298ad94.png",
    title: "XU VERSE",
  },
  {
    id: 2,
    image:
      "https://user-images.githubusercontent.com/43154722/229288340-3712eee8-3e24-459c-a77a-5d6232edfb12.png",
    title: "WILCAT VERSE",
  },
  {
    id: 3,
    image:
      "https://user-images.githubusercontent.com/43154722/229288395-158752e3-93c8-4bb3-9400-40885dc18ddd.png",
    title: "TS VERSE",
  },
  {
    id: 4,
    image:
      "https://user-images.githubusercontent.com/43154722/229288435-c6d2ff92-995d-4124-9d2a-f0df0df6df84.png",
    title: "SU VERSE",
  },
  {
    id: 5,
    image:
      "https://user-images.githubusercontent.com/43154722/230080342-3adc106c-36b2-4d7b-932a-62fc2d482559.png",
    title: "SPELL HOUSE VERSE",
  },
  {
    id: 6,
    image:
      "https://user-images.githubusercontent.com/43154722/229288527-7c2e01ff-fb82-4057-b934-56b20816d2db.png",
    title: "SKEGEE VERSE",
  },
  {
    id: 7,
    image:
      "https://user-images.githubusercontent.com/43154722/229718997-a92d0bff-24d2-443c-93b3-973e2a46db2f.png",
    title: "NCC VERSE",
  },
  {
    id: 8,
    image:
      "https://user-images.githubusercontent.com/43154722/230798281-2a1bcc9f-6c13-4a16-9176-107011232a51.png",
    title: "JSU VERSE",
  },
  {
    id: 9,
    image:
      "https://user-images.githubusercontent.com/43154722/229288564-f31339f1-d4f7-4c38-ac17-60c02aa0e960.png",
    title: "HU VERSE",
  },
  {
    id: 10,
    image:
      "https://user-images.githubusercontent.com/43154722/229719698-191f57a3-585f-43a0-a202-e9ea43467f5e.png",
    title: "HBCU ALUMNI VERSE",
  },
  {
    id: 11,
    image:
      "https://user-images.githubusercontent.com/43154722/229288595-d16e7b90-5140-4b1c-9c16-b6ce33b6c171.png",
    title: "GAU VERSE",
  },
  {
    id: 12,
    image:
      "https://user-images.githubusercontent.com/43154722/230120053-ce2efba5-2cbd-48ef-b9c7-0407a744e38f.png",
    title: "FAM VERSE",
  },
  {
    id: 13,
    image:
      "https://user-images.githubusercontent.com/43154722/229288764-ec1e3912-12fd-4d36-9ad7-70a0525c8770.png",
    title: "BAMA VERSE",
  },
  {
    id: 14,
    image:
      "https://user-images.githubusercontent.com/43154722/229719938-9b47f35c-b82a-4b58-ba28-c2608588ae02.png",
    title: "ASU VERSE",
  },
  {
    id: 15,
    image:
      "https://user-images.githubusercontent.com/43154722/229288717-d3def0f6-4882-4883-856f-4dac2c42b6c8.png",
    title: "AGGIE VERSE",
  },
  {
    id: 16,
    image:
      "https://user-images.githubusercontent.com/43154722/229288310-375ec9db-b4d5-4a3f-83f3-37521298ad94.png",
    title: "XU VERSE",
  },
  {
    id: 17,
    image:
      "https://user-images.githubusercontent.com/43154722/229288340-3712eee8-3e24-459c-a77a-5d6232edfb12.png",
    title: "WILCAT VERSE",
  },
  {
    id: 18,
    image:
      "https://user-images.githubusercontent.com/43154722/229288395-158752e3-93c8-4bb3-9400-40885dc18ddd.png",
    title: "TS VERSE",
  },
  {
    id: 19,
    image:
      "https://user-images.githubusercontent.com/43154722/229288435-c6d2ff92-995d-4124-9d2a-f0df0df6df84.png",
    title: "SU VERSE",
  },
  {
    id: 20,
    image:
      "https://user-images.githubusercontent.com/43154722/230080342-3adc106c-36b2-4d7b-932a-62fc2d482559.png",
    title: "SPELL HOUSE VERSE",
  },
  {
    id: 21,
    image:
      "https://user-images.githubusercontent.com/43154722/229288527-7c2e01ff-fb82-4057-b934-56b20816d2db.png",
    title: "SKEGEE VERSE",
  },
  {
    id: 22,
    image:
      "https://user-images.githubusercontent.com/43154722/229718997-a92d0bff-24d2-443c-93b3-973e2a46db2f.png",
    title: "NCC VERSE",
  },
  {
    id: 23,
    image:
      "https://user-images.githubusercontent.com/43154722/230798281-2a1bcc9f-6c13-4a16-9176-107011232a51.png",
    title: "JSU VERSE",
  },
  {
    id: 24,
    image:
      "https://user-images.githubusercontent.com/43154722/229288564-f31339f1-d4f7-4c38-ac17-60c02aa0e960.png",
    title: "HU VERSE",
  },
  {
    id: 25,
    image:
      "https://user-images.githubusercontent.com/43154722/229719698-191f57a3-585f-43a0-a202-e9ea43467f5e.png",
    title: "HBCU ALUMNI VERSE",
  },
  {
    id: 26,
    image:
      "https://user-images.githubusercontent.com/43154722/229288595-d16e7b90-5140-4b1c-9c16-b6ce33b6c171.png",
    title: "GAU VERSE",
  },
  {
    id: 27,
    image:
      "https://user-images.githubusercontent.com/43154722/230120053-ce2efba5-2cbd-48ef-b9c7-0407a744e38f.png",
    title: "FAM VERSE",
  },
  {
    id: 28,
    image:
      "https://user-images.githubusercontent.com/43154722/229288764-ec1e3912-12fd-4d36-9ad7-70a0525c8770.png",
    title: "BAMA VERSE",
  },
  {
    id: 29,
    image:
      "https://user-images.githubusercontent.com/43154722/229719938-9b47f35c-b82a-4b58-ba28-c2608588ae02.png",
    title: "ASU VERSE",
  },
  {
    id: 30,
    image:
      "https://user-images.githubusercontent.com/43154722/229288717-d3def0f6-4882-4883-856f-4dac2c42b6c8.png",
    title: "AGGIE VERSE",
  },
  {
    id: 31,
    image:
      "https://user-images.githubusercontent.com/43154722/229288310-375ec9db-b4d5-4a3f-83f3-37521298ad94.png",
    title: "XU VERSE",
  },
  {
    id: 32,
    image:
      "https://user-images.githubusercontent.com/43154722/229288340-3712eee8-3e24-459c-a77a-5d6232edfb12.png",
    title: "WILCAT VERSE",
  },
  {
    id: 33,
    image:
      "https://user-images.githubusercontent.com/43154722/229288395-158752e3-93c8-4bb3-9400-40885dc18ddd.png",
    title: "TS VERSE",
  },
  {
    id: 34,
    image:
      "https://user-images.githubusercontent.com/43154722/229288435-c6d2ff92-995d-4124-9d2a-f0df0df6df84.png",
    title: "SU VERSE",
  },
  {
    id: 35,
    image:
      "https://user-images.githubusercontent.com/43154722/230080342-3adc106c-36b2-4d7b-932a-62fc2d482559.png",
    title: "SPELL HOUSE VERSE",
  },
  {
    id: 36,
    image:
      "https://user-images.githubusercontent.com/43154722/229288527-7c2e01ff-fb82-4057-b934-56b20816d2db.png",
    title: "SKEGEE VERSE",
  },
  {
    id: 37,
    image:
      "https://user-images.githubusercontent.com/43154722/229718997-a92d0bff-24d2-443c-93b3-973e2a46db2f.png",
    title: "NCC VERSE",
  },
  {
    id: 38,
    image:
      "https://user-images.githubusercontent.com/43154722/230798281-2a1bcc9f-6c13-4a16-9176-107011232a51.png",
    title: "JSU VERSE",
  },
  {
    id: 39,
    image:
      "https://user-images.githubusercontent.com/43154722/229288564-f31339f1-d4f7-4c38-ac17-60c02aa0e960.png",
    title: "HU VERSE",
  },
  {
    id: 40,
    image:
      "https://user-images.githubusercontent.com/43154722/229719698-191f57a3-585f-43a0-a202-e9ea43467f5e.png",
    title: "HBCU ALUMNI VERSE",
  },
  {
    id: 41,
    image:
      "https://user-images.githubusercontent.com/43154722/229288595-d16e7b90-5140-4b1c-9c16-b6ce33b6c171.png",
    title: "GAU VERSE",
  },
  {
    id: 42,
    image:
      "https://user-images.githubusercontent.com/43154722/230120053-ce2efba5-2cbd-48ef-b9c7-0407a744e38f.png",
    title: "FAM VERSE",
  },
  {
    id: 43,
    image:
      "https://user-images.githubusercontent.com/43154722/229288764-ec1e3912-12fd-4d36-9ad7-70a0525c8770.png",
    title: "BAMA VERSE",
  },
  {
    id: 44,
    image:
      "https://user-images.githubusercontent.com/43154722/229719938-9b47f35c-b82a-4b58-ba28-c2608588ae02.png",
    title: "ASU VERSE",
  },
  {
    id: 45,
    image:
      "https://user-images.githubusercontent.com/43154722/229288717-d3def0f6-4882-4883-856f-4dac2c42b6c8.png",
    title: "AGGIE VERSE",
  },
];
