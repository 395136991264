import React from "react";
import hand from "../../../img/collections/hand.svg";
import token from "../../../img/collections/token.svg";
import coin from "../../../img/coin.svg";
import bitcoin from "../../../img/bitcoin.svg";
import nft from "../../../img/nft.svg";
import polygon from "../../../img/polygon.svg";
import eth from "../../../img/eth.svg";
import cash from "../../../img/cash.svg";
import { Link } from "react-router-dom";

const Hero = () => {
  return (
    <section className="pt-[100px] md:pb-0 w-full ">
      <div className=" mx-auto md:w-full w-11/12 ">
        <div className="section-area-hero element-blur w-full">
          <div className="container mx-auto">
            <div className="flex flex-col md:flex-row justify-between items-center md:gap-x-[40px] gap-0">
              <div className="md:w-full md:max-w-[50%] max-w-[100%] md:mt-[120px] mt-[100px] md:ml-[20px] ml-0 relative top-[-40px] w-11/12 mx-auto">
                <div className="md:ml-0 ml-[20px] sm:ml-0">
                  <div className="bg-gradient-to-r from-[#CD23E4] via-[#808080] to-[#23E42B] md:p-[4px] p-[2.5px] rounded-[30px]  md:w-[280px] w-[190px] h-[260px] md:h-[400px] overflow-hidden z-10 shadow-lg flex items-center justify-center card-1">
                    <div className="bg-white w-full h-full md:p-[8px] p-[5px] overflow-hidden rounded-[30px]">
                      <img
                        src={hand}
                        alt="A"
                        className="w-full h-full rounded-[20px] object-cover"
                      />
                    </div>
                  </div>
                  <div className="bg-gradient-to-r from-[#CD23E4] via-[#808080] to-[#23E42B] md:p-[4px] p-[2.5px]  rounded-[30px] md:w-[350px] w-[210px] md:h-[460px] h-[290px] overflow-hidden z-10 shadow-lg absolute card-2">
                    <div className="bg-white w-full h-full md:p-[8px] p-[5px] overflow-hidden rounded-[30px]">
                      <img
                        src={token}
                        alt="A"
                        className="w-full h-full rounded-[20px] object-cover"
                      />
                    </div>
                  </div>
                </div>
                <div className="z-50 item-6 duration-50 md:h-[130px] h-[90px] md:w-[130px] w-[90px] animate-bounce">
                  <img src={polygon} alt="coin" className=" h-full w-full" />
                </div>
                <div className="z-50 item-5 duration-100 md:h-[150px] h-[100px] w-[100px] md:w-[150px]">
                  <img src={eth} alt="coin" className=" h-full w-full" />
                </div>
                <div className="z-50 item-4 animate-bounce duration-100 md:h-[80px] md:w-[80px] h-[50px] w-[50px]">
                  <img src={cash} alt="coin" className=" h-full w-full" />
                </div>
                <div className="z-50 item-3 duration-100 md:h-[130px] h-[100px] w-[100px] md:w-[130px]">
                  <img src={nft} alt="coin" className=" h-full w-full" />
                </div>
                <div className="z-50 item-2 animate-pulse duration-100 md:h-[161px] md:w-[161px] h-[100px] w-[100px]">
                  <img src={bitcoin} alt="coin" className=" h-full w-full" />
                </div>
                <div className="z-50 item-1 animate-bounce transition duration-500 md:h-[80px] md:w-[80px] h-[65px] w-[65px]">
                  <img src={coin} alt="coin" className=" h-full w-full" />
                </div>
              </div>
              <div className="mt-[120px] md:ml-[30px] ml-0 flex flex-col md:items-start md:justify-start items-center justify-center w-full">
                <h3 className="font-extrabold font-cabin md:leading-[80px]  leading-[36px] md:text-[60px] text-[24px] capitalize w-full md:max-w-[790px] max-w-[214px] dark:text-white text-black md:text-start text-center">
                  Your learning curve begins here.
                </h3>
                <p className="font-ebrima md:leading-[40px] leading-[32px] md:text-[22px] w-full md:max-w-[671px] text-base  md:py-10 py-4 dark:text-white text-black md:text-start text-center">
                  Gain In-depth knowledge on REAL ESTATE, CREDIT, Crypto.,
                  Blockchain Technology, Metaverse, NFTs, WEB3, Digital Assets &
                  Artificial Intelligence.
                </p>
                <Link
                  to="/sign-up"
                  className="md:py-5 py-3 md:w-[261px] w-[149px] flex items-center justify-center rounded-[50px]  bg-gradient-to-r from-[#CD23E4] via-[#808080] to-[#23E42B] md:text-xl text-base text-white font-extrabold font-ebrimabold"
                >
                  Start learning
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Hero;
