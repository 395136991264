import React, { useState, useContext } from "react";
import { SearchBox } from "../../../components/reuseables/Input";
import { FaRegCompass } from "react-icons/fa";
import { RiFilter2Fill } from "react-icons/ri";
import TabContext from "../../../context/TabContext";
import LineImg from "../../../lines/line_img.svg";

const Hero = () => {
  // const [activeTab, setActive] = useState(0);
  const { toggleTab, activeTab } = useContext(TabContext);

  const tabs = [
    {
      id: 0,
      text: "All",
    },
    {
      id: 1,
      text: "Token",
    },
    {
      id: 2,
      text: "Student_ID",
    },
    {
      id: 3,
      text: "Hands",
    },
    {
      id: 4,
      text: "Male",
    },
    {
      id: 5,
      text: "Female",
    },
  ];
  return (
    <>
      <div className="">
        <section className="relative z-5">
          <div className="md:container mx-auto md:w-full w-11/12 flex flex-col items-center">
            <div className="md:py-[150px] py-[60px] w-full flex items-center justify-center">
              <SearchBox placeholder="Search items and collections" />
            </div>
          </div>
          <div className="md:mx-10 mx-0">
            <div className="w-full md:h-[136px] h-[160px] md:p-[3px] md:py-[2px] px-0 md:rounded-[30px] bg-gradient-to-r from-[#CD23E4] via-[#808080] to-[#23E42B] overflow-hidden">
              <div className="w-full h-full dark:bg-[#4E4E4E] bg-white md:rounded-[30px] md:px-8 px-4 flex flex-col md:flex-row md:items-center justify-around py-5 md:justify-between  md:py-5">
                <div className="w-full md:w-[20%] flex items-center  md:border-b-0 border-b-[1.5px] pb-5 md:pb-0 border-[#E5E5E5] border-opacity-30">
                  <FaRegCompass
                    color="#23E42B"
                    className="text-3xl md:text-5xl"
                  />
                  <h3 className="font-ebrimabold font-extrabold text-black dark:text-white md:text-3xl text-base pl-5">
                    Discover
                  </h3>
                </div>
                <ul className="flex justify-start gap-2 scroll-bar overflow-x-auto">
                  {tabs.map((tab) => (
                    <li>
                      <div
                        className={`ml-0 md:px-9 px-4 md:py-3 py-2 text-black dark:text-white md:text-xl text-base font-ebrima cursor-pointer flex items-center justify-center text-center w-full ${
                          activeTab === tab.id
                            ? `dark:bg-[#888888] bg-[#CECECE] bg-opacity-40 rounded-[40px] font-ebrimabold`
                            : `font-medium`
                        }`}
                        onClick={() => toggleTab(tab.id)}
                      >
                        {tab.text}
                      </div>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
          <div className="hidden">
            <div className="px-10 md:w-full w-11/12 flex flex-col items-center justify-center">
              <div className="flex items-center justify-between w-full py-10">
                <div>
                  <h3 className="font-ebrima md:text-xl text-lg text-black dark:text-white font-medium">
                    <span className="opacity-40">Results</span>{" "}
                    <span className="font-ebrimabold font-extrabold">
                      5,28715
                    </span>{" "}
                    <span className="opacity-40">Items</span>
                  </h3>
                </div>
                <div className="flex items-center justify-center md:px-6 md:py-3 px-4 py-4 md:rounded-[40px] rounded-[50%] bg-[#4E4E4E] cursor-pointer ">
                  <RiFilter2Fill className="text-white text-xl" />
                  <h3 className="text-white font-ebrima font-medium md:text-base text-lg pl-3 md:block hidden">
                    Filter
                  </h3>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default Hero;
